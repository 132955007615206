import { useEffect } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useLocalStorage from "../../hooks/useLocalStorage";

const RequireAuth = () => {
  const { auth, setAuth } = useAuth();
  const location = useLocation();
  const [presistedAuth] = useLocalStorage("auth", false);

  useEffect(() => {
    if (presistedAuth) setAuth(presistedAuth);
  }, [presistedAuth, setAuth]);

  if (!presistedAuth) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  return auth?.accessToken ? <Outlet /> : "Loading...";
};

export default RequireAuth;
