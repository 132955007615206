import Vapor from "laravel-vapor";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

/*
    Uploads files to vapor and gets data to be used for /files/upload-file
*/
const useUploadFiles = () => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const uploadFilesToVapor = async (files) => {
    try {
      let filesData = await Promise.all(
        files.map(async (file) => {
          const res = await Vapor.store(file, {
            signedStorageUrl:
              process.env.REACT_APP_VAPOR_URL + "signed-storage-url",
            bucket: process.env.REACT_APP_AWS_BUCKET,
            headers: {
              Authorization: `Bearer ${auth?.accessToken}`,
            },
          });
          res.file_name = file.name;
          res.file_size = file.size;
          res.file_type = res.extension;
          return res;
        })
      );
      return filesData;
    } catch (error) {
      throw error;
    }
  };

  const uploadFilesToBackend = async (files) => {
    const vaporFilesData = await uploadFilesToVapor(files);
    const filesData = await Promise.all(
      vaporFilesData.map(async (file) => {
        const res = await axiosPrivate.post(`/v1/files/upload-file`, {
          ...file,
        });
        return res?.data?.result;
      })
    );
    return filesData;
  };

  return uploadFilesToBackend;
};

export { useUploadFiles };
