import React, { useEffect, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import Button from "../../components/Button";
import PasswordInput from "../../components/forms/PasswordInput";
import axios from "../../api/axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import useNotification from "../../hooks/useNotification";

const RESET_PASSWORD_URL = "/auth/reset";

function ResetPassword() {
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const { notify } = useNotification();
  const navigate = useNavigate();

  const token = searchParams.get("token");

  useEffect(() => {
    const validatePassword = () => {
      if (!password && !confirmPassword) {
        setIsValid(false);
        setErrorMessage("");
        return;
      }
      if (password !== confirmPassword) {
        setIsValid(false);
        setErrorMessage("Passwords don't match");
      } else if (password.length < 8) {
        setIsValid(false);
        setErrorMessage("Password must be at least 8 characters long");
      } else {
        setIsValid(true);
        setErrorMessage("");
      }
    };
    validatePassword();
  }, [password, confirmPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValid) return;
    try {
      const response = await axios.post(RESET_PASSWORD_URL, {
        password,
        token: token,
        password_confirmation: confirmPassword,
      });
      notify(
        "success",
        "Password reset successfully, you can now login with your new password."
      );
      navigate("/auth/login");
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <h2 className="fs-5 fw-bold">Reset Password</h2>
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

        <PasswordInput
          name="password"
          type="password"
          label="New password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          isInvalid={!isValid && password.length > 0}
        />
        <PasswordInput
          name="password_confirmation"
          type="password"
          label="Confirm password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
          isInvalid={!isValid && confirmPassword.length > 0}
        />

        {!isValid && errorMessage && (
          <Form.Text className="text-danger">{errorMessage}</Form.Text>
        )}

        <Button
          variant="primary"
          className="w-100 my-3"
          type="submit"
          disabled={!isValid || !password || !confirmPassword}>
          Reset Password
        </Button>
      </Form>
    </>
  );
}

export default ResetPassword;
