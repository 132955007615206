import useAuth from "../../hooks/useAuth";
import StripeBuyButton from "./StripeBuyButton";
import "./SubscriptionBanner.scss";
import { Card } from "react-bootstrap";

export default function SubscriptionBanner() {
  const { auth } = useAuth();
  return (
    <Card className="text-white shadow-lg subscription-banner m-auto mt-3">
      <Card.Body className="px-5 py-1">
        <div className="d-flex flex-column flex-sm-row align-items-center justify-content-between">
          <div className="mb-0">
            <h2 className="display-6 text-white fw-bold">Unlock Full Access</h2>
            <p className="text-white">
              Subscribe now to access all opportunities listings!
            </p>
          </div>
          <StripeBuyButton
            buyButtonId="buy_btn_1Q9A9eCmbRgTfdgqQaiSwA8M"
            publishableKey="pk_live_51J2GUvCmbRgTfdgq5AazCFz6mGlogaTzoBWWNOj4jvkKWHjeBJnvJAxZULMcPQkgf93VTAxINp4fdO5E53RVnyw000EO5YEMIX"
            clientReferenceId={auth?.user?.organization?.slug}
            customerEmail={auth?.user?.email}
          />
        </div>
      </Card.Body>
    </Card>
  );
}
