import "./ProfileBadge.scss";
import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getAssetPublicUrl } from "../../utils";

function ProfileBadge({ user, mini }) {
  return (
    <div className="user-info p-2 p-lg-4">
      <Link
        to="/settings/my-profile"
        className="text-decoration-none d-flex justify-content-center gap-2">
        <div>
          <Image
            src={getAssetPublicUrl(user?.organization?.logo)}
            height={40}
            width={40}
            style={{ objectFit: "cover" }}
            roundedCircle
          />
        </div>
        {!mini && (
          <div className="ms-2">
            <strong>
              {user?.first_name} {user?.last_name}
            </strong>
            <div className="title">Admin</div>
          </div>
        )}
      </Link>
    </div>
  );
}

export default ProfileBadge;
