import React, { useState } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Card,
  Accordion,
  Spinner,
} from "react-bootstrap";
import { states } from "../../data/states";
import { setAsides } from "../../data/setAsides";

const SearchForm = ({ onSearch, isLoading, disabled }) => {
  const [searchParams, setSearchParams] = useState({
    title: "",
    typeOfSetAside: "",
    state: "",
    zip: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(searchParams);
  };

  return (
    <Accordion className="my-4">
      <Accordion.Item eventKey="0">
        <Accordion.Header className="mt-0">Advanced Search</Accordion.Header>
        <Accordion.Body>
          <Card className="border-0">
            <Card.Body className="bg-light">
              <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col md={6}>
                    <Form.Group controlId="title">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        type="text"
                        name="title"
                        value={searchParams.title}
                        onChange={handleChange}
                        placeholder="Enter title keywords"
                        className="mb-2"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Label>Set aside type</Form.Label>

                    <Form.Group controlId="typeOfSetAside">
                      <Form.Select
                        name="typeOfSetAside"
                        onChange={handleChange}
                        value={searchParams.typeOfSetAside}>
                        <option value="">Choose...</option>
                        {setAsides.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.value}: {option.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={6}>
                    <Form.Label>State</Form.Label>

                    <Form.Group controlId="state">
                      <Form.Select
                        name="state"
                        onChange={handleChange}
                        value={searchParams.state}>
                        <option value="">Choose...</option>
                        {states.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="zip">
                      <Form.Label>ZIP Code</Form.Label>
                      <Form.Control
                        type="text"
                        name="zip"
                        value={searchParams.zip}
                        onChange={handleChange}
                        placeholder="Enter ZIP code"
                        className="mb-2"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="d-grid">
                  <Button
                    variant="primary"
                    type="submit"
                    size="lg"
                    disabled={isLoading || disabled}>
                    {isLoading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                        Searching...
                      </>
                    ) : (
                      "Search Opportunities"
                    )}
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default SearchForm;
