import orgLogoPlaceholder from "./assets/images/logo_placeholder.gif";

export function getAssetPublicUrl(asset) {
  if (!asset) return orgLogoPlaceholder;

  return process.env.REACT_APP_ASSET_URL + "/" + asset?.key;
}

export function formatExternalUrl(url) {
  if (!url) return "";
  if (url.indexOf("://") === -1) return "https://" + url;
  return url;
}

export function formatDate(inputString) {
  const date = new Date(inputString);

  const dateOptions = { year: "numeric", month: "2-digit", day: "2-digit" };
  const timeOptions = { hour: "numeric", minute: "2-digit", hour12: true };

  const localDate = date.toLocaleDateString("en-CA", dateOptions);
  const localTime = date.toLocaleTimeString("en-US", timeOptions);
  const formattedTime = localTime.replace(/^0/, "").replace(/\s/, "");

  return `${localDate} ${formattedTime}`;
}

export const formatDateForAPI = (date) => {
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

export function formatAddress(addressData) {
  const components = [];

  if (addressData.streetAddress) {
    components.push(addressData.streetAddress);
  }

  if (addressData.city && addressData.city.name) {
    components.push(addressData.city.name);
  }

  if (addressData.state && addressData.state.code) {
    components.push(addressData.state.code);
  }

  if (addressData.zip) {
    components.push(addressData.zip);
  }

  if (addressData.country && addressData.country.code) {
    components.push(addressData.country.code);
  }

  return components.filter(Boolean).join(", ");
}

export function formatAssociatedOrgs(orgsStr) {
  const orgs = orgsStr.split(".");
  const keys = ["Department/Ind. Agency"];
  return keys.reduce((acc, key, index) => {
    if (!orgs[index]) return acc;
    acc[key] = orgs[index];
    return acc;
  }, {});
}

export function isCreator(communityRequestData, userData) {
  return communityRequestData?.creator?.id === userData?.organization?.id;
}

export function isCollaborator(communityRequestData, userData) {
  return communityRequestData?.collaborator?.id === userData?.organization?.id;
}
