import Button from "../Button";
import ContentModal from "./ContentModal";

function ConfirmDeclineCollaborationModal({
  show,
  setShow,
  onConfirm,
  onSecondaryAction,
  loading,
}) {
  return (
    <ContentModal show={show} setShow={setShow} className="content-modal">
      <div className="header">
        <h4 className="d-flex align-items-center gap-3">
          <span>Are you sure?</span>
          <span className="warning">This cannot be undone</span>
        </h4>
      </div>
      <div className="content">
        <p>
          If you decline the collaboration, this organization will not be able
          to collaborate on this request. Your request will still be live in
          Community Requests.
        </p>
        <div className="actions d-flex align-content-center justify-content-end gap-3">
          <Button variant="danger" onClick={onConfirm} loading={loading}>
            Decline
          </Button>
        </div>
      </div>
    </ContentModal>
  );
}

export default ConfirmDeclineCollaborationModal;
