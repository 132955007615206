import Button from "../Button";
import ContentModal from "./ContentModal";

function CompleteCollaborationModal({ show, setShow, onConfirm }) {
  return (
    <ContentModal show={show} setShow={setShow} className="content-modal">
      <div className="header">
        <h4 className="d-flex align-items-center gap-3">
          <span>Complete Collaboration?</span>
        </h4>
      </div>
      <div className="content">
        <p>
          Looks like your items have been accepted by the Collaborator. Would
          you like to complete the collaboration?
        </p>
        <div className="actions d-flex align-content-center justify-content-end gap-3">
          <Button variant="primary" className="reverse" onClick={onConfirm}>
            Complete Collaboration
          </Button>
        </div>
      </div>
    </ContentModal>
  );
}

export default CompleteCollaborationModal;
