import OrgHeader from "../../components/navigation/OrgHeader";
import CommunityRequestActivityGrid from "../../components/dashboard/CommunityRequestActivityGrid";

function CommunityRequestsActivity() {
  return (
    <>
      <OrgHeader />
      <CommunityRequestActivityGrid collapsible={false} />
    </>
  );
}

export default CommunityRequestsActivity;
