import "./ImageUploader.scss";

import React, { useState } from "react";
import uploadBackgroundImage from "../assets/images/upload.png";
import { getAssetPublicUrl } from "../utils";

function ImageUploader({
  title,
  maxWidth,
  maxHeight,
  name,
  setImages,
  imageIndex,
  backgroundImage,
  prefilledImage,
  previewOnly /* Used to avoid using asset url when only a local image preview is needed */,
  style,
}) {
  const [error, setError] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const defaultStyle = {
    width: 150,
    height: 150,
    ...style,
  };
  if (!style) style = defaultStyle;
  if (!backgroundImage) backgroundImage = uploadBackgroundImage;
  if (prefilledImage) {
    if (previewOnly && prefilledImage instanceof File) {
      prefilledImage = URL.createObjectURL(prefilledImage);
    } else {
      prefilledImage = getAssetPublicUrl(prefilledImage);
    }
    backgroundImage = prefilledImage;
  }
  const handleImageChange = (e) => {
    let files = e.target.files;
    if (files.length === 0) return;

    let file = files[0];
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = function () {
      let error = null;
      file.width = image.width;
      file.height = image.height;
      if (file.width > maxWidth || file.height > maxHeight) {
        error = "Dimensions too big";
      } else {
        setSelectedImage(URL.createObjectURL(file));
        setImages((images) => {
          return images.map((img, idx) => (idx === imageIndex ? file : img));
        });
      }

      setError(error);
    };
  };

  return (
    <div className={`image-uploader m-3 ${error ? "error" : ""}`}>
      {title && <h4>{title}</h4>}
      <label
        htmlFor={`image-upload-${name}`}
        className="custom-upload"
        style={{
          ...style,
          backgroundImage: `url(${
            selectedImage ? selectedImage : backgroundImage
          })`,
        }}></label>
      <div>{error}</div>
      <input
        type="file"
        accept="image/*"
        name={`image-upload-${name}`}
        id={`image-upload-${name}`}
        className="custom-upload-file-chooser"
        onChange={handleImageChange}
      />
    </div>
  );
}

export default ImageUploader;
