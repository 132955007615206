import "./MobileLayout.scss";

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";

function MobileLayout() {
  return (
    <div className=" mobile min-vh-100">
      <Container className="bg-white min-vh-100 d-flex">
        <Row className="justify-content-center flex-grow-1">
          <Col className="d-flex flex-column">
            <Outlet />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default MobileLayout;
