import "./PasswordInput.scss";

import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Eye, EyeSlash } from "react-bootstrap-icons";

function PasswordInput({
  id,
  label,
  name,
  placeholder,
  onChange,
  size,
  type,
  value,
  validationMessage,
  ...props
}) {
  const [isDisplayed, setIsDisplayed] = useState(false);
  return (
    <Form.Group className="my-3 password-group" controlId={id}>
      {label && <Form.Label>{label}</Form.Label>}
      <InputGroup>
        <Form.Control
          type={isDisplayed ? "text" : "password"}
          size={size ? size : "lg"}
          placeholder={placeholder ? placeholder : label}
          name={name}
          onChange={onChange}
          value={value || ""}
          {...props}
        />
        <InputGroup.Text>
          <span onClick={() => setIsDisplayed(!isDisplayed)}>
            {isDisplayed ? <EyeSlash /> : <Eye />}
          </span>
        </InputGroup.Text>
      </InputGroup>
      {validationMessage && (
        <Form.Control.Feedback type="invalid">
          {validationMessage}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
}

export default PasswordInput;
