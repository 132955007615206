import React from "react";
import { Form } from "react-bootstrap";

function TextInput({
  id,
  label,
  name,
  placeholder,
  onChange,
  size,
  type,
  value,
  validationMessage,
  ...props
}) {
  return (
    <Form.Group className="my-3">
      {label && <Form.Label htmlFor={id}>{label}</Form.Label>}
      <Form.Control
        type={type ? type : "text"}
        size={size ? size : "lg"}
        placeholder={placeholder ? placeholder : label}
        name={name}
        onChange={onChange}
        value={value || ""}
        {...props}
      />
      {validationMessage && (
        <Form.Control.Feedback type="invalid">
          {validationMessage}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
}

export default TextInput;
