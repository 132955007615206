import OrgHeader from "../../components/navigation/OrgHeader";
import AssetTable from "../../components/dashboard/AssetTable";

function CommunityRequestAssets() {
  return (
    <>
      <OrgHeader />
      <AssetTable collapsible={false} />
    </>
  );
}

export default CommunityRequestAssets;
