import "./CommunityRequestDetails.scss";

import React, { useEffect, useState } from "react";
import { Col, Image, Row, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router";

import chatBubbles from "../../assets/images/icons/multi_bubble.png";

import CommunityRequestAssetImages from "../../components/community-request/CommunityRequestAssetImages";
import Button from "../../components/Button";
import StepsProgressBar from "../../components/community-request/StepsProgessBar";
import ReleaseAssetsModal from "../../components/modals/ReleaseAssetsModal";
import useNotification from "../../hooks/useNotification";
import AcceptAssetsModal from "../../components/modals/AcceptAssetsModal";
import CompleteCollaborationModal from "../../components/modals/CompleteCollaborationModal";
import MapContainer from "../../components/MapContainer";
import OrgHeader from "../../components/navigation/OrgHeader";
import { getAssetPublicUrl, isCollaborator, isCreator } from "../../utils";
import ChatPanel from "../../components/ChatPanel";
import useAuth from "../../hooks/useAuth";
import {
  useAcceptAssets,
  useCancelCommunityRequest,
  useCompleteCommunityRequest,
  useRejectAssets,
  useReleaseAssets,
} from "../../api/communityRequests";
import CommunityRequestActionButton from "../../components/community-request/CommunityRequestActionButton";
import { CollaborationStatus } from "../../constants";
import RejectAssetsModal from "../../components/modals/RejectAssetsModal";
import { Link } from "react-router-dom";
import CancelCommunityRequestOrCollaboration from "../../components/modals/CancelCommunityRequestOrCollaboration";
import { useCancelCollaboration } from "../../api/collaborations";

function CommunityRequestDetails({ communityRequestData }) {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { notify } = useNotification();
  const releaseAssetsMutation = useReleaseAssets();
  const acceptAssetsMutation = useAcceptAssets();
  const completeCommunityRequestMutation = useCompleteCommunityRequest();
  const rejectAssetsMutation = useRejectAssets();
  const cancelCollaborationMutation = useCancelCollaboration();
  const cancelCommunityRequestMutation = useCancelCommunityRequest();

  const asset = communityRequestData?.asset_packages[0];

  const [currentStep, setCurrentStep] = useState(
    communityRequestData?.collaboration?.asset_package_status
  );
  const [showReleaseAssetsModal, setShowReleaseAssetsModal] = useState(false);
  const [showAcceptAssetsModal, setShowAcceptAssetsModal] = useState(false);
  const [showCompleteCollaborationModal, setShowCompleteCollaborationModal] =
    useState(false);
  const [showRejectAssetsModal, setShowRejectAssetsModal] = useState(false);
  const [
    showCancelCommunityRequestOrCollaborationModal,
    setShowCancelCommunityRequestOrCollaborationModal,
  ] = useState(false);

  const [communityRequestStatus, setCommunityRequestStatus] =
    useState("ASSETS_READY");

  // TODO: ACL needs to be improved, this is a temporary fix
  useEffect(() => {
    if (
      !isCollaborator(communityRequestData, auth?.user) &&
      !isCreator(communityRequestData, auth?.user)
    ) {
      navigate("/dashboard");
    }
  }, [auth?.user, communityRequestData, navigate]);

  useEffect(() => {
    const currentCommunityRequestStatus =
      communityRequestData?.collaboration?.status;
    if (currentCommunityRequestStatus === CollaborationStatus.COMPLETED) {
      setCurrentStep(CollaborationStatus.COMPLETED);
    } else {
      setCurrentStep(communityRequestData?.collaboration?.asset_package_status);
    }
  }, [
    communityRequestData?.collaboration?.asset_package_status,
    communityRequestData?.collaboration?.status,
  ]);

  const releaseAssets = async () => {
    try {
      setShowReleaseAssetsModal(false);
      const { data } = await releaseAssetsMutation.mutateAsync(
        communityRequestData?.slug
      );
      notify("success", "Assets released successfully");
      setCommunityRequestStatus("ASSETS_RELEASED");
      setCurrentStep(data?.result?.collaboration?.asset_package_status);
    } catch (error) {}
  };

  const acceptAssets = async () => {
    setShowAcceptAssetsModal(false);
    const { data } = await acceptAssetsMutation.mutateAsync(
      communityRequestData?.slug
    );
    notify("success", "Assets accepted successfully");
    setCommunityRequestStatus("ASSETS_ACCEPTED");
    setCurrentStep(data?.result?.collaboration?.asset_package_status);
  };

  const completeCommunityRequest = async () => {
    setShowCompleteCollaborationModal(false);
    const { data } = await completeCommunityRequestMutation.mutateAsync(
      communityRequestData?.slug
    );
    notify("success", "Collaboration completed successfully.");
    setCommunityRequestStatus("COLLABORATION_COMPLETED");
    setCurrentStep(data?.result?.collaboration?.status);
  };

  const cancelCollaboration = async () => {
    try {
      const { data } = await cancelCollaborationMutation.mutateAsync(
        communityRequestData?.collaboration?.slug
      );
      setCommunityRequestStatus("COLLABORATION_CANCELED");
      notify("success", "Collaboration Canceled Successfully.");
      setShowCancelCommunityRequestOrCollaborationModal(false);
      setCurrentStep(data?.result?.collaboration?.status);
    } catch (error) {}
  };
  const cancelCommunityRequest = async () => {
    try {
      const { data } = await cancelCommunityRequestMutation.mutateAsync(
        communityRequestData?.slug
      );
      setCommunityRequestStatus("COMMUNITY_REQUEST_CANCELED");
      setShowCancelCommunityRequestOrCollaborationModal(false);
      notify("success", "Community Request Canceled Successfully.");
      setCurrentStep(data?.result?.collaboration?.status);
      navigate("/dashboard");
    } catch (error) {}
  };

  const rejectAssets = async () => {
    try {
      const { data } = await rejectAssetsMutation.mutateAsync(
        communityRequestData?.slug
      );
      setCommunityRequestStatus("ASSETS_REJECTED");
      notify("success", "Assets rejected.");
      setShowRejectAssetsModal(false);
      setCurrentStep(data?.result?.collaboration?.status);
    } catch (error) {}
  };

  /*
    TODO: remove this when the API is updated to return the correct public URL
  */
  const assetImages = communityRequestData?.asset_packages[0]?.files?.map(
    (file) => ({
      url: getAssetPublicUrl(file),
    })
  );

  return (
    <>
      <OrgHeader />
      <div className="community-request-details pt-2">
        <Row className="header justify-content-center flex-wrap">
          <Col lg={6}>
            <h1 className="pb-0 mb-0">Community Request Details</h1>
          </Col>
          <Col lg={6}>
            <div className="button-wrapper text-end">
              {communityRequestStatus === "COLLABORATION_COMPLETED" ? (
                "Collaboration Completed"
              ) : (
                <div className="d-flex align-items-center justify-content-end mb-3">
                  {isCreator(communityRequestData, auth?.user) && (
                    <Link
                      to="#"
                      variant="danger"
                      className="me-3 fs-6 text-black"
                      onClick={() =>
                        setShowCancelCommunityRequestOrCollaborationModal(true)
                      }>
                      Cancel Collaboration/Request
                    </Link>
                  )}
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col lg={6} md={12}>
            <strong className="community-request-id">
              Community Request ID: <span>{communityRequestData.slug}</span>
            </strong>
            <strong className="campaign-name">
              Campaign Name: <span>{communityRequestData.title} </span>
            </strong>
            <CommunityRequestActionButton
              communityRequestData={communityRequestData}
              setShowReleaseAssetsModal={setShowReleaseAssetsModal}
              setShowAcceptAssetsModal={setShowAcceptAssetsModal}
              setShowCompleteCommunityRequestModal={
                setShowCompleteCollaborationModal
              }
              setShowRejectAssetsModal={setShowRejectAssetsModal}
            />
            <Row className="mt-4 h-auto">
              <Col>
                <>
                  <div className="d-flex justify-content-between">
                    <Image
                      src={getAssetPublicUrl(communityRequestData.creator.logo)}
                      style={{ marginRight: 10 }}
                      className="logo"
                    />
                    <Button
                      className="chat-btn mx-auto"
                      onClick={() => {
                        window.scrollTo(0, document.body.scrollHeight);
                      }}>
                      <Image src={chatBubbles} />
                    </Button>
                  </div>
                  <dt>Creator</dt>
                  <dd>
                    {communityRequestData.creator.name}
                    <br />
                    ID: {communityRequestData.creator.id}
                  </dd>
                  <dt>Pick Up Address</dt>
                  <dd>
                    {communityRequestData.pickup_complete_address ??
                      "Not provided"}
                  </dd>
                  <dt>Phone</dt>
                  <dd>
                    {communityRequestData.creator?.phone ?? "Not provided"}
                  </dd>
                  <dt>Date</dt>
                  <dd>{communityRequestData.created_at_date}</dd>
                  <dt>Asset Type</dt>
                  <dd>{asset?.category?.name ?? "Not provided"}</dd>
                  <dt>Pieces</dt>
                  <dd>{asset?.quantity ?? "Not provided"}</dd>
                  <dt>Items</dt>
                  <dd>{asset?.item ?? "Not provided"}</dd>
                </>
              </Col>
              <Col>
                <>
                  <Image
                    src={getAssetPublicUrl(
                      communityRequestData.collaborator.logo
                    )}
                    className="logo"
                  />
                  <dt>Collaborator</dt>
                  <dd>
                    {communityRequestData.collaborator.name}
                    <br />
                    ID: {communityRequestData.collaborator.id}
                  </dd>
                  <dt>Drop Off Address</dt>
                  <dd>
                    {communityRequestData.dropoff_complete_address ??
                      "Not Provided"}
                  </dd>
                  <dt>Phone</dt>
                  <dd>
                    {communityRequestData.creator?.phone ?? "Not Provided"}
                  </dd>
                  <dt>Time</dt>
                  <dd>{communityRequestData.created_at_time}</dd>
                  <dt>Weight</dt>
                  <dd>{asset?.weight ?? "Not Provided"}</dd>
                </>
              </Col>
            </Row>
            <Row className="h-auto">
              <Col>
                <dt className="mb-2">Instructions</dt>
                <dd>
                  <p>
                    {communityRequestData.instructions ??
                      "No instructions provided."}
                  </p>
                </dd>
              </Col>
            </Row>
          </Col>
          <Col lg={6} md={12}>
            <div className="d-flex flex-column">
              <div>
                <h4 className="mb-3">Progress</h4>
                <StepsProgressBar
                  currentStep={currentStep}
                  communityRequestStatus={
                    communityRequestData?.collaboration_status
                  }
                  assetsStatus={
                    communityRequestData?.collaboration?.asset_package_status
                  }
                />
              </div>
              <Tabs defaultActiveKey="assetImages">
                <Tab eventKey="assetImages" title="Asset Images">
                  <div className="d-flex align-items-start flex-column">
                    {assetImages && (
                      <CommunityRequestAssetImages images={assetImages} />
                    )}
                  </div>
                </Tab>
                <Tab eventKey="map" title="Map">
                  <MapContainer
                    pickup={{
                      address: communityRequestData.pickup_complete_address,
                      coordinates: communityRequestData.pickup_coordinates,
                    }}
                    dropoff={{
                      address: communityRequestData.dropoff_complete_address,
                      coordinates: communityRequestData.dropoff_coordinates,
                    }}
                  />
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <ChatPanel
              currentChannel={`${communityRequestData.slug}`}
              isPrivate={true}
              members={[
                communityRequestData?.creator?.slug,
                communityRequestData?.collaborator?.slug,
              ]}
            />
          </Col>
        </Row>
        <ReleaseAssetsModal
          show={showReleaseAssetsModal}
          setShow={setShowReleaseAssetsModal}
          onConfirm={releaseAssets}
        />
        <AcceptAssetsModal
          show={showAcceptAssetsModal}
          setShow={setShowAcceptAssetsModal}
          onConfirm={acceptAssets}
        />
        <CompleteCollaborationModal
          show={showCompleteCollaborationModal}
          setShow={setShowCompleteCollaborationModal}
          onConfirm={completeCommunityRequest}
        />
        <RejectAssetsModal
          show={showRejectAssetsModal}
          setShow={setShowRejectAssetsModal}
          onConfirm={rejectAssets}
          onSecondaryAction={() =>
            navigate(
              `/community-requests/${communityRequestData.slug}/incident-reports/new`
            )
          }
        />
        <CancelCommunityRequestOrCollaboration
          show={showCancelCommunityRequestOrCollaborationModal}
          setShow={setShowRejectAssetsModal}
          onConfirm={cancelCollaboration}
          onSecondaryAction={cancelCommunityRequest}
        />
      </div>
    </>
  );
}

export default CommunityRequestDetails;
