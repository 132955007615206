import "./ContentModal.scss";

import React from "react";
import { Modal } from "react-bootstrap";
import closeIcon from "../../assets/images/icons/close2.png";

function ContentModal({ show, setShow, children, ...props }) {
  const handleClose = () => setShow(false);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      keyboard={false}
      backdrop="static"
      {...props}>
      <Modal.Body className="px-5 py-4">
        <img
          className="ms-auto close position-absolute end-0 me-3"
          src={closeIcon}
          width={26}
          alt="Close"
          onClick={() => setShow(false)}
        />
        {children}
      </Modal.Body>
    </Modal>
  );
}

export default ContentModal;
