import { useMemo, useState } from "react";
import OrgHeader from "../../components/navigation/OrgHeader";
import DataGrid from "../../components/DataGrid";
import followedIcon from "../../assets/images/icons/followed.png";
import followIcon from "../../assets/images/icons/follow.png";
import { Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

function CommunityEvents() {
  const [{ pageIndex }, setPagination] = useState({
    pageIndex: 0,
  });
  const [data] = useState([
    {
      eventTitle: "WA Charity Event",
      organization: "Aloha Honolulu",
      industry: "Nonprofit",
      description: "Charity Event Discussion",
      date: "August 4th, 2023",
      time: "7pm PST",
      eventLink: "honolulu.com/event",
      followed: true,
    },
    {
      eventTitle: "Live Webinar",
      organization: "americanNGO",
      industry: "Nonprofit",
      description: "OKR Planning Webinar",
      date: "August 4th, 2023",
      time: "7pm PST",
      eventLink: "www.myevent.com",
    },
    {
      eventTitle: "WA Charity Event",
      organization: "Aloha Honolulu",
      industry: "Nonprofit",
      description: "Charity Event Discussion",
      date: "August 4th, 2023",
      time: "7pm PST",
      eventLink: "honolulu.com/event",
      followed: true,
    },
    {
      eventTitle: "Live Webinar",
      organization: "americanNGO",
      industry: "Nonprofit",
      description: "OKR Planning Webinar",
      date: "August 4th, 2023",
      time: "7pm PST",
      eventLink: "www.myevent.com",
    },
    {
      eventTitle: "WA Charity Event",
      organization: "Aloha Honolulu",
      industry: "Nonprofit",
      description: "Charity Event Discussion",
      date: "August 4th, 2023",
      time: "7pm PST",
      eventLink: "honolulu.com/event",
      followed: true,
    },
    {
      eventTitle: "Live Webinar",
      organization: "americanNGO",
      industry: "Nonprofit",
      description: "OKR Planning Webinar",
      date: "August 4th, 2023",
      time: "7pm PST",
      eventLink: "www.myevent.com",
    },
    {
      eventTitle: "WA Charity Event",
      organization: "Aloha Honolulu",
      industry: "Nonprofit",
      description: "Charity Event Discussion",
      date: "August 4th, 2023",
      time: "7pm PST",
      eventLink: "honolulu.com/event",
      followed: true,
    },
    {
      eventTitle: "Live Webinar",
      organization: "americanNGO",
      industry: "Nonprofit",
      description: "OKR Planning Webinar",
      date: "August 4th, 2023",
      time: "7pm PST",
      eventLink: "www.myevent.com",
    },
  ]);
  const columns = useMemo(
    () => [
      { header: "Event Title", accessorKey: "eventTitle" },
      { header: "Organization", accessorKey: "organization" },
      { header: "industry", accessorKey: "industry" },
      { header: "Description", accessorKey: "description" },
      { header: "Date", accessorKey: "date" },
      { header: "Time", accessorKey: "time" },
      { header: "Event Link", accessorKey: "eventLink" },
      {
        accessorKey: "action",
        cell: ({ cell }) => {
          const followed = cell.row.original.followed;

          return (
            <div className="text-end">
              <Button variant="light">
                <Image src={followed ? followedIcon : followIcon} />
              </Button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <OrgHeader />
      <h3 className="mb-0">Community Events</h3>
      <Link to="/events/followed" className="sublink fw-bold ms-2">
        Events followed by my organization
      </Link>
      <DataGrid
        columns={columns}
        // onRowClick={(e, row) => navigate(`/event/${row.eventTitle}`)}
        data={data}
        pageIndex={pageIndex}
        setPagination={setPagination}
        pageCount={data?.result?.last_page}
        comingSoon
      />
    </>
  );
}

export default CommunityEvents;
