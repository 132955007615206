import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "../../components/Button";
import TextArea from "../../components/forms/TextArea";
import TextInput from "../../components/forms/TextInput";
import Panel from "../../components/Panel";
import useFetch from "../../hooks/useFetch";
import useForm from "../../hooks/useForm";

function EventsForm() {
  const [{ isLoading }] = useFetch();

  const submitForm = (finishLater) => {};

  const { formData, handleChange, validated } = useForm({}, () => {
    submitForm();
  });
  return (
    <>
      <Form noValidate validated={validated}>
        <Row className="justify-content-center">
          <Col md={7}>
            <h5 className="mt-5 fw-bold">Event Listings</h5>
            <div className="subheading">
              Create an event listing for your organization using this form. add
              up to 3 events.
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={7}>
            <Panel>
              <div>
                <Row>
                  <Col md={10}>
                    <h2 className="pt-3">Event Listing</h2>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <TextInput
                      id="organization"
                      label="Organization"
                      name="organization"
                      onChange={handleChange}
                      value={formData.organization}
                      required
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      label="Org ID"
                      id="orgId"
                      name="orgId"
                      onChange={handleChange}
                      value={formData.orgId}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <TextInput
                      id="email"
                      label="Email address"
                      name="email"
                      type="email"
                      onChange={handleChange}
                      value={formData.email}
                      required
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      label="Industry"
                      id="industry"
                      name="industry"
                      onChange={handleChange}
                      value={formData.industry}
                      required
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <TextInput
                      id="eventTitle"
                      label="Event Title"
                      name="eventTitle"
                      onChange={handleChange}
                      value={formData.eventTitle}
                      required
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      id="eventWebsite"
                      label="Event Website"
                      name="eventWebsite"
                      onChange={handleChange}
                      value={formData.eventWebsite}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <TextInput
                      id="eventDate"
                      label="Date"
                      name="eventDate"
                      onChange={handleChange}
                      value={formData.eventDate}
                      required
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      id="eventTime"
                      label="Time"
                      name="eventTime"
                      onChange={handleChange}
                      value={formData.eventTime}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mt-3">
                    <TextArea
                      id="eventDescription"
                      label="Event Description"
                      name="eventDescription"
                      onChange={handleChange}
                      value={formData.eventDescription}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex flex-row-reverse my-3">
                    <Button
                      type="submit"
                      size="lg"
                      variant="primary"
                      className="ms-2"
                      disabled={!formData.eventTitle || isLoading}>
                      Publish
                    </Button>
                  </Col>
                </Row>
              </div>
            </Panel>
          </Col>
        </Row>

        <Row>
          <Col></Col>
        </Row>
      </Form>
    </>
  );
}

export default EventsForm;
