import React, { useState } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import TextInput from "../../components/forms/TextInput";
import PasswordInput from "../../components/forms/PasswordInput";
import axios from "../../api/axios";
import useForm from "../../hooks/useForm";
import SuccessfulRegistration from "../../components/auth/SuccessfulRegistration";
import * as Sentry from "@sentry/react";

const REGISTER_URL = "/register";

function Signup() {
  const [errors, setErrors] = useState({});
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const { formData, handleChange, handleSubmit, validated } = useForm(
    {},
    (event) => {
      submitForm(event);
    }
  );

  const submitToHubspot = async (e) => {
    e.preventDefault();
    const portalId = "23190256";
    const formGuid = "590b4550-60ec-4160-81c6-f0c6929d5d57";
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`;

    const payload = {
      fields: [
        {
          name: "email",
          value: formData.email,
        },
        {
          name: "firstname",
          value: formData.first_name,
        },
        {
          name: "lastname",
          value: formData.last_name,
        },
        {
          name: "0-2/name", // generated name from hubspot
          value: formData.organization_name,
        },
      ],
      context: {
        pageUri: window.location.href,
        pageName: "register",
      },
    };
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});
    const form = e.currentTarget;

    try {
      const fd = new FormData(form);

      fd.append("is_active", 0);
      const response = await axios.post(REGISTER_URL, fd, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setRegistrationSuccess(true);

      await submitToHubspot(e).catch((error) => {
        Sentry.captureMessage("Error submitting form to hubspot:", {
          tags: {
            source: "register",
          },
          extra: {
            error,
          },
        });
      });
    } catch (error) {
      const errMessage = error?.response?.data?.message;
      const errors = error?.response?.data?.errors;
      if (!errors) setErrors(["Something went wrong, please try again later!"]);
      else {
        setErrors(Object.values(errors));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {registrationSuccess ? (
        <SuccessfulRegistration />
      ) : (
        <Form onSubmit={handleSubmit} validated={validated}>
          <div className="d-flex justify-content-between align-items-center pb-3">
            <h2 className="fs-4 fw-bold m-0">Create an account</h2>
            <div>
              Already have an account?{" "}
              <Link
                to="/auth/login"
                className="mt-3 fs-6 text-decoration-underline">
                Login here
              </Link>
            </div>
          </div>
          {errors.length > 0 && (
            <Alert variant="danger">
              {errors.map((error, index) => (
                <div key={index}>{error}</div>
              ))}
            </Alert>
          )}
          <Row>
            <Col>
              <TextInput
                name="organization_name"
                label="organization name*"
                placeholder={" "}
                id="organization_name"
                value={formData.organization_name}
                onChange={handleChange}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <TextInput
                name="first_name"
                label="first name*"
                placeholder={" "}
                id="first_name"
                value={formData.first_name}
                onChange={handleChange}
                required
              />
            </Col>
            <Col md={6}>
              <TextInput
                name="last_name"
                label="last name*"
                placeholder={" "}
                id="last_name"
                value={formData.last_name}
                onChange={handleChange}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInput
                name="email"
                label="email*"
                placeholder={" "}
                id="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <PasswordInput
                name="password"
                type="password"
                label="password*"
                placeholder={" "}
                id="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </Col>
          </Row>

          <Row className="my-2">
            <Col className="gap-3">
              <input
                type="checkbox"
                name="agree"
                id="agree"
                value={formData.agree}
                onChange={handleChange}
                checked={formData.agree}
                className="d-inline-block me-2"
                required
              />
              <span>
                By creating an account you are agreeing to Impart Inc.'s{" "}
                <Link
                  to="https://impactpartnersnetwork.com/legal"
                  target="_blank">
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link
                  to="https://impactpartnersnetwork.com/legal"
                  target="_blank">
                  Privacy policy
                </Link>
                .
              </span>
            </Col>
          </Row>
          <Button
            variant="primary"
            className="w-100 my-3"
            type="submit"
            disabled={loading}
            loading={loading}>
            Create Account
          </Button>
        </Form>
      )}
    </>
  );
}

export default Signup;
