import CommunityRequestsList from "../../components/community-request/CommunityRequestsList";
import OrgHeader from "../../components/navigation/OrgHeader";
import { CommunityRequestStatus } from "../../constants";

function CommunityRequests({ status }) {
  let label = "";
  if (status === CommunityRequestStatus.COMMUNITY_REQUEST_STATUS_IN_REVIEW)
    label = "Community Requests In Review";
  else if (status === CommunityRequestStatus.COMMUNITY_REQUEST_STATUS_PUBLISHED)
    label = "Community Requests In Progress";
  else if (status === CommunityRequestStatus.COMMUNITY_REQUEST_STATUS_COMPLETED)
    label = "Completed Community Requests";
  return (
    <>
      <OrgHeader />
      <CommunityRequestsList title={`${label}`} status={status} />
    </>
  );
}

export default CommunityRequests;
