import "./MapContainer.scss";

import React, { useEffect, useRef } from "react";

function MapContainer({ pickup, dropoff }) {
  const ref = useRef();

  const setMarker = (location, label, map) => {
    const infoWindow = new window.google.maps.InfoWindow({
      content: `<strong>${label}</strong><p>${location.address}</p>`,
      ariaLabel: label,
    });
    let marker = new window.google.maps.Marker({
      map: map,
      position: location.coordinates,
    });
    marker.addListener("click", () => {
      infoWindow.open({
        anchor: marker,
        map,
      });
    });
    return { marker, infoWindow };
  };

  useEffect(() => {
    let map = new window.google.maps.Map(ref.current, {
      center: pickup.coordinates,
      zoom: 15,
    });
    const pickupObj = setMarker(pickup, "Pick Up Address", map);
    setMarker(dropoff, "Drop Off Address", map);
    window.google.maps.event.addListenerOnce(map, "tilesloaded", function () {
      pickupObj.infoWindow.open(map, pickupObj.marker);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div ref={ref} id="map" style={{ width: 490, height: 440 }} />;
}

export default MapContainer;
