import React from "react";
import { Form } from "react-bootstrap";

function Select({
  id,
  label,
  name,
  placeholder,
  onChange,
  options,
  selectOption,
  ...props
}) {
  return (
    <Form.Group controlId={id} className="my-3">
      <Form.Label>{label}</Form.Label>
      <Form.Select
        size="lg"
        placeholder={placeholder ? placeholder : label}
        name={name}
        onChange={onChange}
        {...props}>
        <option value="">{selectOption ? selectOption : "Choose..."}</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
}

export default Select;
