import Button from "../Button";
import ContentModal from "./ContentModal";
import creatorLogo from "../../assets/images/creator_logo.png";
import closeIcon from "../../assets/images/icons/close2.png";

import { useNavigate } from "react-router-dom";

function AcceptAssetsModal({ show, setShow, onConfirm }) {
  const navigate = useNavigate();
  return (
    <ContentModal show={show} setShow={setShow} className="content-modal">
      <div className="header">
        <h4 className="d-flex align-items-center gap-3">
          <span>Accept Assets from Community Request Creator?</span>
        </h4>
      </div>
      <div className="content">
        <p>
          Once assets are accepted you cannot cancel this collaboration, If you
          choose to cancel the collaboration it will be archived.
        </p>
        <div className="actions d-flex align-content-center justify-content-end gap-3">
          <Button variant="success" onClick={onConfirm}>
            Accept Assets
          </Button>
        </div>
      </div>
    </ContentModal>
  );
}

export default AcceptAssetsModal;
