import "./CommunityRequestDetails.scss";

import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import OrgBadge from "../../components/OrgBadge";
import CommunityRequestAssetImages from "../../components/community-request/CommunityRequestAssetImages";

import Button from "../../components/Button";
import OrgHeader from "../../components/navigation/OrgHeader";
import AcceptContractModal from "../../components/modals/AcceptContractModal";
import useNotification from "../../hooks/useNotification";
import MapContainer from "../../components/MapContainer";
import { useSendCollaborationRequest } from "../../api/collaborations";
import { useQueryClient } from "@tanstack/react-query";
import { CollaborationStatus } from "../../constants";
import { getAssetPublicUrl } from "../../utils";
import ChatPanel from "../../components/ChatPanel";
import useAuth from "../../hooks/useAuth";

function OpenCommunityRequest({ communityRequestData }) {
  const queryClient = useQueryClient();
  const { notify } = useNotification();
  const { auth } = useAuth();
  const [isPending, setIsPending] = useState(false);
  const collaborationMutation = useSendCollaborationRequest();
  const chatRef = useRef();
  const isRejected =
    communityRequestData.collaboration_status === CollaborationStatus.REJECTED;
  const isMyOrg =
    communityRequestData?.organization?.id === auth?.user?.organization?.id;
  const assetImages = communityRequestData?.asset_packages[0]?.files?.map(
    (file) => ({
      url: getAssetPublicUrl(file),
    })
  );
  const [showAcceptContractModal, setShowAcceptContractModal] = useState(false);

  const asset = communityRequestData?.asset_packages[0];

  const acceptContract = () => {
    setShowAcceptContractModal(false);
    notify(
      "success",
      "Your collaboration request has been sent, pending approval."
    );
  };
  useEffect(() => {
    setIsPending(
      communityRequestData.collaboration_status === CollaborationStatus.PENDING
    );
  }, [communityRequestData]);
  return (
    <div className="community-request-details">
      <OrgHeader />
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      <h1 className="pb-0 mb-0">Community Requests</h1>

      <div className="community-request-header my-4 p-2 px-4">
        <div className="d-flex justify-content-between flex-column flex-md-row gap-3">
          <div>
            <strong className="community-request-id">
              Community Request ID: <span>{communityRequestData.slug}</span>
            </strong>
            <strong className="campaign-name">
              Campaign Name: <span>{communityRequestData.title} </span>
            </strong>
          </div>
          {!isMyOrg && (
            <div>
              {isRejected ? (
                <div className="fs-3">Unable to Collaborate at this time</div>
              ) : isPending ? (
                <Button className="outline pending" disabled={true}>
                  Pending
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className="outline"
                  onClick={() => {
                    // setShowAcceptContractModal(true);
                    collaborationMutation.mutate(communityRequestData.slug, {
                      onSuccess: (data) => {
                        queryClient.invalidateQueries({
                          queryKey: [
                            "communityRequests",
                            communityRequestData.slug,
                          ],
                        });
                        setIsPending(true);
                        notify(
                          "success",
                          "Your collaboration request has been sent, pending approval"
                        );
                      },
                    });
                  }}>
                  Collaborate
                </Button>
              )}
            </div>
          )}
        </div>
      </div>

      <Row className="d-flex justify-content-center">
        <Col lg={6} md={12}>
          <div className="creator">
            <OrgBadge
              org={communityRequestData.creator}
              isCreator
              onChatClick={() => {
                window.scrollTo(0, document.body.scrollHeight);
              }}
            />
          </div>
          <Row>
            <Col>
              <dt className="mb-2">Instructions</dt>
              <dd>
                <p>
                  {communityRequestData.instructions ??
                    "No instructions provided."}
                </p>
              </dd>
            </Col>
          </Row>
          <Row className="border-top border-bottom">
            <Col>
              <dt>Pick Up Address</dt>
              <dd>
                {communityRequestData.pickup_complete_address ?? "Not provided"}
              </dd>
            </Col>
            <Col>
              <dt>Drop Off Address</dt>
              <dd>
                {communityRequestData.dropoff_complete_address ??
                  "Not provided"}
              </dd>
            </Col>
          </Row>
          <Row className="mt-4 h-auto">
            <Col>
              <dt>Date</dt>
              <dd>{communityRequestData?.created_at_date}</dd>
              <dt>Phone</dt>
              <dd>{communityRequestData?.creator?.phone ?? "Not provided"}</dd>
              <dt>Asset Type</dt>
              <dd>{asset?.category?.name ?? "Not provided"}</dd>
            </Col>
            <Col>
              <dt>time</dt>
              <dd>{communityRequestData.created_at_time}</dd>

              <dt>Pieces</dt>
              <dd>{asset?.quantity ?? "Not provided"}</dd>
              <dt>Items</dt>
              <dd>{asset?.item ?? "Not provided"}</dd>
            </Col>
          </Row>
        </Col>
        <Col lg={6} md={12}>
          <Tabs defaultActiveKey="assetImages">
            <Tab eventKey="assetImages" title="Asset Images">
              <div className="d-flex align-items-start flex-column">
                {assetImages && (
                  <CommunityRequestAssetImages images={assetImages} />
                )}
              </div>
            </Tab>
            <Tab eventKey="map" title="Map">
              <MapContainer
                pickup={{
                  address: communityRequestData.pickup_complete_address,
                  coordinates: communityRequestData.pickup_coordinates,
                }}
                dropoff={{
                  address: communityRequestData.dropoff_complete_address,
                  coordinates: communityRequestData.dropoff_coordinates,
                }}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
      <Row>
        <Col>
          <ChatPanel
            currentChannel={`${communityRequestData.slug}`}
            ref={chatRef}
          />
        </Col>
      </Row>
      <AcceptContractModal
        show={showAcceptContractModal}
        setShow={setShowAcceptContractModal}
        onConfirm={acceptContract}
      />
    </div>
  );
}

export default OpenCommunityRequest;
