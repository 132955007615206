import { useQuery } from "@tanstack/react-query";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const useFetchStats = (pageIndex = 0) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["dashboardStats"],
    queryFn: () =>
      axiosPrivate.get(`/v1/dashboard/stats`).then((res) => res.data),
    useErrorBoundary: true,
  });
};

export { useFetchStats };
