import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { CommunityRequestStatus } from "../constants";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const usePostCommunityRequest = () => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: (data) => {
      return axiosPrivate.post("/v1/community-requests/add", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
  });
};

const useEditCommunityRequest = () => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: ({ id, data }) => {
      return axiosPrivate.post(`/v1/community-requests/${id}/edit`, data);
    },
  });
};

const usePublishCommunityRequest = () => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: (id) => {
      return axiosPrivate.post(`/v1/community-requests/${id}/publish`);
    },
  });
};

const useCancelCommunityRequest = () => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: (id) => {
      return axiosPrivate.post(`/v1/community-requests/${id}/cancel`);
    },
  });
};

const useReleaseAssets = () => {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => {
      return axiosPrivate.post(
        `/v1/collaborations/community-requests/${id}/release-assets`
      );
    },
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ["communityRequests", data?.result?.slug],
      });
    },
  });
};

const useAcceptAssets = () => {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => {
      return axiosPrivate.post(
        `/v1/collaborations/community-requests/${id}/accept-assets`
      );
    },
    onSuccess: ({ data }) => {
      return queryClient.invalidateQueries({
        queryKey: ["communityRequests", data?.result?.slug],
      });
    },
  });
};
const useRejectAssets = () => {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => {
      return axiosPrivate.post(
        `/v1/collaborations/community-requests/${id}/deny-assets`
      );
    },
    onSuccess: ({ data }) => {
      return queryClient.invalidateQueries({
        queryKey: ["communityRequests", data?.result?.slug],
      });
    },
  });
};
const useCompleteCommunityRequest = () => {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => {
      return axiosPrivate.post(
        `/v1/collaborations/community-requests/${id}/complete`
      );
    },
    onSuccess: ({ data }) => {
      return queryClient.invalidateQueries({
        queryKey: ["communityRequests", data?.result?.slug],
      });
    },
  });
};

const useFetchCommunityRequest = (id) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["communityRequests", id],
    queryFn: () =>
      axiosPrivate.get(`/v1/community-requests/${id}`).then((res) => res.data),
    select: (data) => {
      const result = data.result;
      return {
        ...result,
        created_at_date: new Date(result.created_at).toLocaleDateString(),
        created_at_time: new Date(result.created_at).toLocaleTimeString(
          "en-US",
          { hour: "numeric", minute: "2-digit" }
        ),
      };
    },
    useErrorBoundary: true,
    enabled: !!id,
    cacheTime: 0,
  });
};

const useFetchCommunityRequests = (pageIndex = 0) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["communityRequests", pageIndex],
    queryFn: () =>
      axiosPrivate
        .get(`/v1/community-requests?page=${pageIndex + 1}`)
        .then((res) => res.data),
    useErrorBoundary: true,
    keepPreviousData: true,
  });
};

const useFetchMyCommunityRequests = (pageIndex = 0) => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  return useQuery({
    queryKey: ["myCommunityRequests", pageIndex],
    queryFn: () =>
      axiosPrivate
        .get(
          `/v1/community-requests?filter_by[organization_id]=${
            auth?.user?.organization?.id
          }&page=${pageIndex + 1}`
        )
        .then((res) => res.data),
    useErrorBoundary: true,
    keepPreviousData: true,
  });
};

const useFetchMyActiveCommunityRequests = (pageIndex = 0) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["myCommunityRequests", pageIndex],
    queryFn: () =>
      axiosPrivate
        .get(
          `/v1/community-requests?include_collaborations=${1}&page=${
            pageIndex + 1
          }&filter_by[is_active]=${1}`
        )
        .then((res) => res.data),
    useErrorBoundary: true,
    keepPreviousData: true,
  });
};

const useFetchCommunityRequestsByFilters = ({
  orgId,
  status,
  isActive,
  pageIndex,
}) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["filteredCommunityRequests", orgId, status, pageIndex],
    queryFn: () => {
      let filterQuery = [];
      if (orgId) filterQuery.push(`filter_by[organization_slug]=${orgId}`);
      if (status) filterQuery.push(`filter_by[status]=${status}`);
      if (isActive) filterQuery.push(`filter_by[is_active]=${isActive}`);

      if (filterQuery.length > 1) filterQuery = filterQuery.join("&");
      else filterQuery = filterQuery[0];
      return axiosPrivate
        .get(`/v1/community-requests?${filterQuery}&page=${pageIndex + 1}`)
        .then((res) => res.data);
    },
    useErrorBoundary: true,
    keepPreviousData: true,
  });
};

const useFetchCommunityRequestDrafts = (pageIndex = 0) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["communityRequestsDrafts", pageIndex],
    queryFn: () =>
      axiosPrivate
        .get(
          `/v1/community-requests?filter_by[status]=${
            CommunityRequestStatus.COMMUNITY_REQUEST_STATUS_DRAFT
          }&page=${pageIndex + 1}`
        )
        .then((res) => res.data),
    useErrorBoundary: true,
    keepPreviousData: true,
  });
};

const useFetchPublishedCommunityRequests = (pageIndex = 0) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["communityRequests", pageIndex],
    queryFn: () =>
      axiosPrivate
        .get(
          `/v1/community-requests/published?filter_by[is_active]=0&only_subscribed=true&page=${
            pageIndex + 1
          }`
        )
        .then((res) => res.data),
    useErrorBoundary: true,
    cacheTime: 0,
  });
};

const useFetchAssetCategories = () => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["assetCategories"],
    queryFn: () =>
      axiosPrivate
        .get(`/v1/community-requests/assets/asset-categories`)
        .then((res) => res.data),
    useErrorBoundary: true,
  });
};

export {
  usePostCommunityRequest,
  useEditCommunityRequest,
  usePublishCommunityRequest,
  useCancelCommunityRequest,
  useAcceptAssets,
  useCompleteCommunityRequest,
  useRejectAssets,
  useReleaseAssets,
  useFetchCommunityRequest,
  useFetchCommunityRequests,
  useFetchMyCommunityRequests,
  useFetchMyActiveCommunityRequests,
  useFetchCommunityRequestDrafts,
  useFetchPublishedCommunityRequests,
  useFetchCommunityRequestsByFilters,
  useFetchAssetCategories,
};
