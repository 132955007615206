import { useState, useCallback } from "react";
import { camelizeKeys, decamelizeKeys } from "humps";

const useFetch = (url) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  const doFetch = useCallback(async (url, options = undefined) => {
    setIsLoading(true);
    setError(null);
    const token = "";

    if (options && options.body)
      options.body = JSON.stringify(decamelizeKeys(options.body));
    const fetchOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      ...options,
    };

    return await fetch(url, fetchOptions)
      .then((response) => response.json())
      .then((response) => {
        response = camelizeKeys(response);
        setData(response);
        return response;
      })
      .catch((err) => {
        setError(err.message);
        return err;
      })
      .finally(() => setIsLoading(false));
  }, []);
  return [{ data, error, isLoading }, doFetch];
};

export default useFetch;
