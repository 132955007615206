import "./CommunityRequestAssetImages.scss";

import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

function CommunityRequestAssetImages({ images, noPreview }) {
  const [preview, setPreview] = useState();
  useEffect(() => {
    if (images.length > 0) setPreview(images[0]);
  }, [images]);

  if (images.length === 0) return "No asset images to display.";
  return (
    <div className="asset-images">
      {!noPreview && (
        <div
          className="preview mb-4"
          style={{
            backgroundImage: `url(${preview?.url})`,
            maxWidth: "100%",
          }}></div>
      )}

      <Row className="thumbnails justify-content-md-start justify-content-center">
        {images.map((image, i) => (
          <Col
            className="d-flex d-md-block justify-content-center"
            xs={6}
            md={3}
            key={i}
            onClick={() => setPreview(image)}>
            <div
              className="thumbnail"
              style={{
                backgroundImage: `url(${image?.url})`,
                width: 150,
                height: 150,
              }}></div>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default CommunityRequestAssetImages;
