export const setAsides = [
  {
    label: "Total Small Business Set-Aside (FAR 19.5)",
    value: "SBA",
  },
  {
    label: "Partial Small Business Set-Aside (FAR 19.5)",
    value: "SBP",
  },
  {
    label: "8(a) Set-Aside (FAR 19.8)",
    value: "8A",
  },
  {
    label: "8(a) Sole Source (FAR 19.8)",
    value: "8AN",
  },
  {
    label:
      "Historically Underutilized Business (HUBZone) Set-Aside (FAR 19.13)",
    value: "HZC",
  },
  {
    label:
      "Historically Underutilized Business (HUBZone) Sole Source (FAR 19.13)",
    value: "HZS",
  },
  {
    label:
      "Service-Disabled Veteran-Owned Small Business (SDVOSB) Set-Aside (FAR 19.14)",
    value: "SDVOSBC",
  },
  {
    label:
      "Service-Disabled Veteran-Owned Small Business (SDVOSB) Sole Source (FAR 19.14)",
    value: "SDVOSBS",
  },
  {
    label: "Women-Owned Small Business (WOSB) Program Set-Aside (FAR 19.15)",
    value: "WOSB",
  },
  {
    label: "Women-Owned Small Business (WOSB) Program Sole Source (FAR 19.15)",
    value: "WOSBSS",
  },
  {
    label:
      "Economically Disadvantaged WOSB (EDWOSB) Program Set-Aside (FAR 19.15)",
    value: "EDWOSB",
  },
  {
    label:
      "Economically Disadvantaged WOSB (EDWOSB) Program Sole Source (FAR 19.15)",
    value: "EDWOSBSS",
  },
  {
    label: "Local Area Set-Aside (FAR 26.2)",
    value: "LAS",
  },
  {
    label:
      "Indian Economic Enterprise (IEE) Set-Aside (specific to Department of Interior)",
    value: "IEE",
  },
  {
    label:
      "Indian Small Business Economic Enterprise (ISBEE) Set-Aside (specific to Department of Interior)",
    value: "ISBEE",
  },
  {
    label:
      "Buy Indian Set-Aside (specific to Department of Health and Human Services, Indian Health Services)",
    value: "BICiv",
  },
  {
    label:
      "Veteran-Owned Small Business Set-Aside (specific to Department of Veterans Affairs)",
    value: "VSA",
  },
  {
    label:
      "Veteran-Owned Small Business Sole source (specific to Department of Veterans Affairs)",
    value: "VSS",
  },
];
