import Button from "../Button";
import ContentModal from "./ContentModal";
import collaboratorLogo from "../../assets/images/collaborator_logo.png";

function AcceptContractModal({ show, setShow, onConfirm }) {
  return (
    <ContentModal show={show} setShow={setShow} className="content-modal">
      <div className="header">
        <h4 className="d-flex align-items-center gap-3">
          <img src={collaboratorLogo} width={50} alt="Org logo" />
          <span>contract agreement</span>
        </h4>
      </div>
      <div className="content">
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam enim
          explicabo excepturi possimus repellendus magnam velit numquam,
          voluptate ullam libero, quia cumque doloribus vel dicta adipisci
        </p>
        <div className="actions d-flex align-content-center justify-content-end gap-3">
          <Button variant="success" onClick={onConfirm}>
            Accept Contract
          </Button>
        </div>
      </div>
      <div className="disclaimer">
        *DISCLAIMER LOREM IPSUMP SIT AMET, CONSECTETUREM ADISICIOING ELIT, SED
        DO. LOREM IPSUMP SIT AMET, CONSECTETUREM DISICIOING ELIT, SED DO.
      </div>
    </ContentModal>
  );
}

export default AcceptContractModal;
