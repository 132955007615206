import { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import DataGrid from "../../components/DataGrid";
import deleteIcon from "../../assets/images/icons/delete.png";
import editIcon from "../../assets/images/icons/edit.png";
import plusIcon from "../../assets/images/icons/plus.png";
import { Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

function EventsTable() {
  const [{ pageIndex }, setPagination] = useState({
    pageIndex: 0,
  });
  const [data] = useState([
    {
      eventTitle: "Event Title",
      organization: "Org 1",
      industry: "Industry",
      description: "OKR Planning Webinar",
      date: "August 4th, 2023",
      time: "7pm PST",
      eventLink: "www.myevent.com",
      followed: true,
    },
    {
      eventTitle: "Event Title",
      organization: "Org 1",
      industry: "Industry",
      description: "OKR Planning Webinar",
      date: "August 4th, 2023",
      time: "7pm PST",
      eventLink: "www.myevent.com",
    },
    {
      eventTitle: "Event Title",
      organization: "Org 1",
      industry: "Industry",
      description: "OKR Planning Webinar",
      date: "August 4th, 2023",
      time: "7pm PST",
      eventLink: "www.myevent.com",
    },
    {
      eventTitle: "Event Title",
      organization: "Org 1",
      industry: "Industry",
      description: "OKR Planning Webinar",
      date: "August 4th, 2023",
      time: "7pm PST",
      eventLink: "www.myevent.com",
    },
  ]);
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      { header: "Event Title", accessorKey: "eventTitle" },
      { header: "Organization", accessorKey: "organization" },
      { header: "Industry", accessorKey: "industry" },
      { header: "Description", accessorKey: "description" },
      { header: "Date", accessorKey: "date" },
      { header: "Time", accessorKey: "time" },
      { header: "Event Link", accessorKey: "eventLink" },
      {
        accessorKey: "action",
        cell: ({ cell }) => {
          return (
            <div className="text-end">
              <Button
                variant="light"
                className="p-1"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate("/settings/events/1");
                }}>
                <Image src={editIcon} />
              </Button>
              <Button variant="light" className="p-1">
                <Image src={deleteIcon} />
              </Button>
            </div>
          );
        },
      },
    ],
    [navigate]
  );

  return (
    <div className="mt-5">
      <div className="actions text-end">
        <Link to="../events/add" className="d-inline-flex gap-2 text-black">
          <Image src={plusIcon} height={20} />
          Add event
        </Link>
      </div>
      <DataGrid
        columns={columns}
        onRowClick={(e, row) => navigate(`/event/${row.eventTitle}`)}
        data={data}
        pageIndex={pageIndex}
        setPagination={setPagination}
        pageCount={data?.result?.last_page}
        comingSoon={true}
      />
    </div>
  );
}

export default EventsTable;
