import Button from "../Button";
import ContentModal from "./ContentModal";

function ReleaseAssetsModal({ show, setShow, onConfirm }) {
  return (
    <ContentModal show={show} setShow={setShow} className="content-modal">
      <div className="header">
        <h4 className="d-flex align-items-center gap-3">
          <span>Release Assets to Collaborator?</span>
        </h4>
      </div>
      <div className="content">
        <p>
          Once assets are released you cannot cancel this request, If you choose
          to cancel the request it will be archived.
        </p>
        <div className="actions d-flex align-content-center justify-content-end gap-3">
          <Button variant="primary" className="reverse" onClick={onConfirm}>
            Release Assets
          </Button>
        </div>
      </div>
    </ContentModal>
  );
}

export default ReleaseAssetsModal;
