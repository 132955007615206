import React from "react";
import EventsForm from "../../forms/settings/EventsForm";
import EventsTable from "./EventsTable";
import { useParams } from "react-router";

function Events() {
  let { id } = useParams();
  return <div>{!id ? <EventsTable /> : <EventsForm />}</div>;
}

export default Events;
