import { useMutation, useQuery } from "@tanstack/react-query";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useLocalStorage from "../hooks/useLocalStorage";

const useFetchOrganizations = (pageIndex = 1) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["organizations", pageIndex],
    queryFn: () =>
      axiosPrivate
        .get(`/v1/organizations/?page=${pageIndex + 1}`)
        .then((res) => res.data),
    keepPreviousData: true,
  });
};

const useFetchOrganization = (id) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["organizations", id],
    queryFn: () =>
      axiosPrivate.get(`/v1/organizations/${id}`).then((res) => res.data),
  });
};

const useFetchMyData = () => {
  const axiosPrivate = useAxiosPrivate();
  const { setAuth } = useAuth();
  // eslint-disable-next-line no-unused-vars
  const [_, setPresistedAuth] = useLocalStorage("auth", "");

  return useQuery({
    queryKey: ["me"],
    queryFn: () => axiosPrivate.get(`/v1/me`).then((res) => res.data),
    onSuccess: (data) => {
      setAuth((prev) => ({ ...prev, user: data?.result }));
      setPresistedAuth((prev) => ({ ...prev, user: data?.result }));
    },
    enabled: false,
  });
};

const useFetchNetworkOrganizations = (pageIndex) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["networkOrganizations", pageIndex],
    queryFn: () =>
      axiosPrivate
        .get(`/v1/my-subscription?page=${pageIndex + 1}`)
        .then((res) => res.data),
    keepPreviousData: true,
  });
};

const useEditOrg = () => {
  const axiosPrivate = useAxiosPrivate();
  const { refetch } = useFetchMyData();

  return useMutation({
    mutationFn: ({ id, data }) => {
      return axiosPrivate.post(`/v1/organizations/${id}/edit`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    onSuccess: (data) => {
      refetch();
    },
  });
};

const useSubscribeToOrg = () => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: (id) => {
      return axiosPrivate.post(`/v1/users/organizations/${id}/subscribe`);
    },
  });
};

const useUnsubscribeToOrg = () => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: (id) => {
      return axiosPrivate.post(`/v1/users/organizations/${id}/unsubscribe`);
    },
  });
};

export {
  useFetchOrganizations,
  useFetchOrganization,
  useSubscribeToOrg,
  useUnsubscribeToOrg,
  useFetchNetworkOrganizations,
  useEditOrg,
  useFetchMyData,
};
