import "./ChatPanel.scss";

import React, { forwardRef, useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";

import { usePubNub } from "pubnub-react";
import {
  Chat,
  MessageList,
  MessageInput,
  useUser,
} from "@pubnub/react-chat-components";

import { Col, Row } from "react-bootstrap";

const theme = "light";

const ChatPanel = forwardRef(function ChatPanel(
  { currentChannel, isPrivate, members },
  ref
) {
  const { auth } = useAuth();
  const [displayChat, setDisplayChat] = useState(false);
  const myUser = {
    id: auth?.user?.slug,
    name: `${auth?.user?.first_name} ${auth?.user?.last_name} (${auth?.user?.organization?.name})`,
  };
  const [currentUser, error, isLoading] = useUser({ uuid: myUser?.id });
  const pubnubClient = usePubNub();

  useEffect(() => {
    const setUserMetadata = async () => {
      await pubnubClient.setUUID(myUser.id);
      await pubnubClient.objects.setUUIDMetadata({
        data: { name: myUser.name },
      });
    };
    setUserMetadata().then(async () => {
      setDisplayChat(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pubnubClient]);

  if (isPrivate && !members?.includes(myUser.id)) return <></>;

  return (
    <div className="chat-container">
      <div className="chat-panel mt-5 pt-4">
        <strong className="chat-id mb-4">
          Community Request Chat ID {currentChannel}
        </strong>
        {!isLoading && displayChat && (
          <>
            <Chat
              users={[currentUser]}
              currentChannel={currentChannel}
              theme={theme}>
              <Row className="g-0">
                <Col>
                  <MessageList fetchMessages={25} />
                  <MessageInput senderInfo={true} typingIndicator />
                </Col>
              </Row>
              <div ref={ref}></div>
            </Chat>
          </>
        )}
      </div>
    </div>
  );
});

export default ChatPanel;
