import React, { useState } from "react";
import { Form, Alert } from "react-bootstrap";
import Button from "../../components/Button";
import TextInput from "../../components/forms/TextInput";
import axios from "../../api/axios";

const FORGOT_PASSWORD_URL = "/auth/forgot";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    try {
      const response = await axios.post(FORGOT_PASSWORD_URL, {
        email,
      });
      setMessage(response?.data?.message);
    } catch (error) {
      setMessage(error?.response?.data?.message);
    }
  };
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <h2 className="fs-5 fw-bold">Forgot Password</h2>
        {message && <Alert variant="info">{message}</Alert>}
        <p className="fs-6">
          Enter the email address associated with your account and we'll send
          you a link to reset your password.
        </p>

        <TextInput
          name="email"
          label="email"
          type="email"
          placeholder=" "
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Button variant="primary" className="w-100 my-3" type="submit">
          Continue
        </Button>
      </Form>
    </>
  );
}

export default ForgotPassword;
