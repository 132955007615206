import React from "react";
import { Form } from "react-bootstrap";

function Checkbox({ id, label, name, onChange, checked, ...props }) {
  return (
    <Form.Group controlId={id} className="mb-3">
      <Form.Check
        type="checkbox"
        name={name}
        label={label}
        onChange={onChange}
        checked={checked || false}
        {...props}
      />
    </Form.Group>
  );
}

export default Checkbox;
