import React from "react";
import { Col, Row } from "react-bootstrap";
import { formatAddress, formatAssociatedOrgs, formatDate } from "../../utils";
import { BoxArrowUpRight } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import {
  useFetchOpportunity,
  useFetchOpportunityDescription,
} from "../../api/opportunities";

function Opportunity() {
  const { noticeId } = useParams();
  const { data: opp, isLoading: isLoadingOpportunity } =
    useFetchOpportunity(noticeId);
  const { data: descriptionData, isLoading: isLoadingDescription } =
    useFetchOpportunityDescription(opp?.description);

  if (isLoadingOpportunity) return <Loading />;
  return (
    <div>
      <h3 className="d-flex align-items-center gap-2">
        Opportunity:{" "}
        <a
          href={opp?.uiLink}
          target="_blank"
          rel="noreferrer"
          className="d-flex align-items-center gap-2">
          <span>{opp?.title}</span>
          <span className="fs-5">
            <BoxArrowUpRight />
          </span>
        </a>
      </h3>
      <Row className="py-3">
        <Col>
          <dl>
            <dt>Posted Date</dt>
            <dd>{opp?.postedDate}</dd>

            {opp?.placeOfPerformance &&
              Object.keys(opp?.placeOfPerformance).length > 0 && (
                <>
                  <dt>Place of Performance</dt>
                  <dd>{formatAddress(opp?.placeOfPerformance)}</dd>
                </>
              )}
          </dl>
        </Col>
        <Col>
          <dl>
            <dt>Response Deadline</dt>
            <dd>{formatDate(opp?.responseDeadLine)}</dd>
            {opp?.fullParentPathName &&
              Object.entries(formatAssociatedOrgs(opp?.fullParentPathName)).map(
                ([key, value]) => (
                  <React.Fragment key={key}>
                    <dt>{key}</dt>
                    <dd>{value}</dd>
                  </React.Fragment>
                )
              )}
          </dl>
        </Col>
      </Row>
      <h4>Classification</h4>
      <dl>
        {opp?.typeOfSetAside && (
          <>
            <dt>Set Aside Code</dt>
            <dd>
              {opp?.typeOfSetAside}
              {"  "}
              <small>{opp?.typeOfSetAsideDescription}</small>
            </dd>
          </>
        )}

        <dt>Classification Code</dt>
        <dd>{opp?.classificationCode}</dd>
        <dt>NAICS Code</dt>
        <dd>{opp?.naicsCode}</dd>
      </dl>

      <h4>Description</h4>
      {isLoadingDescription ? (
        <Loading />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: descriptionData }} />
      )}
    </div>
  );
}

export default Opportunity;
