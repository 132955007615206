import React from "react";
import BackButton from "./BackButton";
import OrgBadge from "../OrgBadge";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

function OrgHeader() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const location = useLocation();
  const org = auth?.user?.organization;
  const isPreview = location.state?.preview;
  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex justify-content-start">
        <BackButton
          onClick={(e) => {
            e.preventDefault();
            if (isPreview) {
              navigate("/settings/org-profile", {
                state: {
                  fromPreview: true,
                },
              });
            } else {
              navigate(-1);
            }
          }}
        />
      </div>
      <div className="d-flex justify-content-end gap-5">
        <OrgBadge
          org={org}
          onClick={() => navigate(`/organization/${org?.slug}`)}
        />
      </div>
    </div>
  );
}

export default OrgHeader;
