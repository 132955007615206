import "./BackButton.scss";

import React from "react";
import { Image } from "react-bootstrap";
import backButtonIcon from "../../assets/images/icons/back.png";

function BackButton({ to, onClick }) {
  return (
    <div>
      <button className="back" onClick={onClick}>
        <Image src={backButtonIcon} /> <span>Back</span>
      </button>
    </div>
  );
}

export default BackButton;
