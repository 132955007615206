import "./Pricing.scss";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "../components/Button";
import { toast } from "react-toastify";

function Pricing() {
  const PLAN_DURATION = {
    monthly: "Monthly",
    yearly: "Yearly",
  };
  const [selectedDuration, setSelectedDuration] = useState(
    PLAN_DURATION.monthly
  );

  const plans = [
    {
      name: "Starter - 2 Seats",
      cost: {
        monthly: 35,
        yearly: 240,
      },
      features: ["Feature 1", "Feature 2", "Feature 3"],
    },
    {
      name: "Pro - 5 Seats",
      cost: {
        monthly: 75,
        yearly: 480,
      },
      features: ["Feature 1", "Feature 2", "Feature 3"],
    },
    {
      name: "Large Teams - 10 Seats",
      cost: {
        monthly: 250,
        yearly: 1290,
      },
      features: ["Feature 1", "Feature 2", "Feature 3"],
    },
  ];

  return (
    <div className="pricing">
      <h1>Settings</h1>
      <Row className="text-center">
        <Col>
          <h2>Pricing Plans</h2>
          <div className="plan-switcher pb-5">
            <Button
              onClick={() => setSelectedDuration(PLAN_DURATION.monthly)}
              className={`${
                selectedDuration === PLAN_DURATION.monthly ? "active" : ""
              } me-2`}>
              {PLAN_DURATION.monthly}
            </Button>
            <Button
              onClick={() => setSelectedDuration(PLAN_DURATION.yearly)}
              className={`${
                selectedDuration === PLAN_DURATION.yearly ? "active" : ""
              }`}>
              {PLAN_DURATION.yearly}
            </Button>
          </div>
          <div className="plans mt-5">
            <Row className="justify-content-center align-items-stretch">
              {plans.map((plan, idx) => {
                return (
                  <Col md={3} key={idx}>
                    <div className="plan">
                      <h4 className="pb-4">{plan.name}</h4>
                      <div className="cost">
                        {selectedDuration === PLAN_DURATION.monthly ? (
                          <>
                            <strong>${plan.cost.monthly}</strong> / mo
                          </>
                        ) : (
                          <>
                            <strong>${plan.cost.yearly}</strong> / yr
                          </>
                        )}
                      </div>
                      <ul className="features text-center mt-3">
                        {plan.features.map((feature, idx) => (
                          <li key={idx}>{feature}</li>
                        ))}
                      </ul>
                      <div className="action">
                        <Button
                          onClick={() => {
                            toast("test!");
                          }}>
                          Do Something
                        </Button>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
            <Row className="justify-content-center text-end py-5">
              <Col md={9}>
                Need more seats? <a href="/">Contact us</a>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Pricing;
