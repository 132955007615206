import "./CommunityRequestActivityGrid.scss";

import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataGrid from "../DataGrid";
import { useFetchMyCommunityRequests } from "../../api/communityRequests";

function CommunityRequestActivityGrid({ collapsible, limit }) {
  const [{ pageIndex }, setPagination] = useState({
    pageIndex: 0,
  });
  const { isLoading, data } = useFetchMyCommunityRequests(pageIndex);

  let communityRequests = data?.result?.data;
  const navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        header: "Request ID",
        accessorKey: "slug",
      },
      { header: "Title", accessorKey: "title" },
      {
        header: "Asset Description",
        accessorKey: "assetDescription",
        cell: ({ cell }) => {
          const assets = cell.row.original.asset_packages;
          if (assets?.length === 0) return <></>;
          const asset = assets[0];
          return (
            <>
              {asset?.category?.name}{" "}
              <strong className="d-block">{asset?.item}</strong>
            </>
          );
        },
      },
      {
        header: "Originating Location",
        accessorKey: "pickup_complete_address",
      },
      { header: "Date & Time", accessorKey: "created_at" },
      {
        header: "Status",
        accessorKey: "status",
        cell: ({ cell }) => {
          const collaborationStatus = cell.row.original.collaboration_status;
          const statusList = [
            "published",
            "pending",
            "accepted",
            "completed",
            "rejected",
          ];

          const status =
            !collaborationStatus || collaborationStatus >= statusList.length
              ? statusList[0]
              : statusList[collaborationStatus];

          return (
            <>
              <span className={`status text-capitalize ${status}`}>
                {status}
              </span>
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="community-request-activity-grid">
      <DataGrid
        title="My Community Requests"
        columns={columns}
        onRowClick={(e, row) => navigate(`/community-requests/${row.slug}`)}
        isLoading={isLoading}
        data={communityRequests ?? []}
        collapsible={collapsible}
        pageIndex={pageIndex}
        setPagination={setPagination}
        pageCount={data?.result?.last_page}
      />
    </div>
  );
}

export default CommunityRequestActivityGrid;
