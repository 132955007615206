import { useMemo, useState } from "react";
import OrgHeader from "../../components/navigation/OrgHeader";
import DataGrid from "../../components/DataGrid";
import Opportunity from "./Opportunity";
import { formatDate } from "../../utils";
import { useParams, useNavigate } from "react-router-dom";
import SearchForm from "../../components/opportunities/SearchForm";
import { useFetchOpportunities } from "../../api/opportunities";
import useAuth from "../../hooks/useAuth";
import { OrgPaymentPlans } from "../../constants";
import SubscriptionBanner from "../../components/opportunities/SubscriptionBanner";

function SearchOpportunities() {
  const { auth } = useAuth();

  const { noticeId } = useParams();
  const navigate = useNavigate();

  const limitResults =
    auth?.user?.organization?.payment_plan !== OrgPaymentPlans.PREMIUM;
  const PAGE_SIZE = limitResults ? 8 : 25;

  const [{ pageIndex }, setPagination] = useState({
    pageIndex: 0,
  });
  const [searchParams, setSearchParams] = useState({});
  const { isLoading, data } = useFetchOpportunities(
    searchParams,
    PAGE_SIZE,
    pageIndex,
    !noticeId
  );

  const opportunitiesData = data?.opportunitiesData;
  const totalRecords = data?.totalRecords;

  const handleSearch = (params) => {
    setSearchParams(params);
    setPagination({ pageIndex: 0 });
  };

  const columns = useMemo(
    () => [
      {
        header: "Title",
        accessorKey: "title",
        cell: ({ cell }) => {
          const title = cell.row.original.title;
          return (
            <span title={title}>
              {title.length > 50 ? title.substring(0, 50) + "..." : title}
            </span>
          );
        },
      },
      {
        header: "Associated Orgs",
        accessorKey: "fullParentPathName",
        cell: ({ cell }) => {
          const pathName = cell.row.original.fullParentPathName;
          const orgs = pathName?.split(".");
          return (
            (orgs?.length > 0 && <span>{orgs[0].toLowerCase()}</span>) || ""
          );
        },
      },
      {
        header: "Place of Performance",
        cell: ({ cell }) => {
          const address = cell.row.original.placeOfPerformance;
          const cityState = [address?.city?.name, address?.state?.code].filter(
            (elt) => elt
          );
          return (
            <>
              {cityState.join(", ")} {address?.zip}
            </>
          ); //
        },
      },
      { header: "SetAside", accessorKey: "typeOfSetAside" },
      { header: "Posted", accessorKey: "postedDate" },
      {
        header: "Response Deadline",
        accessorKey: "responseDeadLine",
        cell: ({ cell }) => {
          const date = new Date(cell.row.original.responseDeadLine);
          return formatDate(date);
        },
      },
    ],
    []
  );
  return (
    <>
      <OrgHeader />

      {noticeId ? (
        <Opportunity noticeId={noticeId} />
      ) : (
        <>
          <h3 className="mb-0">Opportunities</h3>
          {!isLoading && limitResults && <SubscriptionBanner />}

          <SearchForm
            onSearch={handleSearch}
            isLoading={isLoading}
            disabled={limitResults}
          />
          <DataGrid
            columns={columns}
            onRowClick={(e, row) => navigate(`/opportunities/${row.noticeId}`)}
            data={opportunitiesData ?? []}
            pageIndex={pageIndex}
            setPagination={setPagination}
            pageCount={parseInt(totalRecords / PAGE_SIZE, 10) + 1}
            isLoading={isLoading}
            limitResults={limitResults}
          />
        </>
      )}
    </>
  );
}

export default SearchOpportunities;
