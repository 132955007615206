import { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import DataGrid from "../../components/DataGrid";
import OrgHeader from "../../components/navigation/OrgHeader";
import OrgBadge from "../../components/OrgBadge";
import { useFetchOrganizations } from "../../api/organizations";
import SubscribeButton from "../../components/SubscribeButton";

function FindOrganizations() {
  const [{ pageIndex }, setPagination] = useState({
    pageIndex: 0,
  });
  const { isLoading, data } = useFetchOrganizations(pageIndex);

  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        header: "Organization",
        accessorKey: "org",
        cell: ({ cell }) => (
          <>
            <OrgBadge org={cell.row.original} mini />
          </>
        ),
      },
      { header: "Location", accessorKey: "location" },
      { header: "Website", accessorKey: "website" },
      {
        header: "",
        accessorKey: "rowid",
        cell: ({ cell }) => {
          const currentOrg = cell.row.original;
          return (
            <div className="d-flex justify-content-end">
              <SubscribeButton org={currentOrg} />
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <OrgHeader />
      <h3 className="mb-0">Find Organization</h3>
      <p className="subheading">
        Stay up-to-date on the latest news and information from organizations{" "}
        you care about - simply visit this page to find them and subscribe!
      </p>
      <Link to="/my-network" className="sublink fw-bold">
        My Network
      </Link>
      <div className="subheading">
        Click the "My Network" link to view a list of all the organizations you
        are subscribed to.
      </div>
      <DataGrid
        columns={columns}
        onRowClick={(e, row) => navigate(`/organization/${row.slug}`)}
        isLoading={isLoading}
        data={data?.result?.data ?? []}
        pageIndex={pageIndex}
        setPagination={setPagination}
        pageCount={data?.result?.last_page}
      />
    </>
  );
}

export default FindOrganizations;
