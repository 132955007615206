import "./Panel.scss";

import React, { useState } from "react";
import { Collapse, Image } from "react-bootstrap";
import arrowDownIcon from "../assets/images/icons/arrow_down.png";
import arrowRightIcon from "../assets/images/icons/arrow_right.png";

function Panel({ title, children, collapsible, ...props }) {
  const [open, setOpen] = useState(true);
  const style = { cursor: collapsible ? "pointer" : "auto" };
  return (
    <>
      {title && (
        <h2 onClick={() => setOpen(collapsible ? !open : open)} style={style}>
          {title}{" "}
          {collapsible && <Image src={open ? arrowDownIcon : arrowRightIcon} />}
        </h2>
      )}
      <Collapse in={open}>
        <div className="p-0 m-0">{children}</div>
      </Collapse>
    </>
  );
}

export default Panel;
