import OrgHeader from "../../components/navigation/OrgHeader";
import CommunityRequestDraftsTable from "../../components/dashboard/CommunityRequestDraftsTable";

function CommunityRequestDrafts() {
  return (
    <>
      <OrgHeader />
      <CommunityRequestDraftsTable collapsible={false} />
    </>
  );
}

export default CommunityRequestDrafts;
