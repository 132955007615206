import { toast } from "react-toastify";
import successIcon from "../assets/images/icons/toast/success_icon.png";
import infoIcon from "../assets/images/icons/toast/info_icon.png";
import warningIcon from "../assets/images/icons/toast/warn_icon.png";
import errorIcon from "../assets/images/icons/toast/error_icon.png";

const useNotification = () => {
  const notify = (type, message) => {
    let notifyFn, icon, title;

    switch (type) {
      case "success":
        notifyFn = toast.success;
        icon = successIcon;
        title = "Success!";
        break;
      case "error":
        notifyFn = toast.error;
        icon = errorIcon;
        title = "ERROR!";
        break;
      case "warning":
        notifyFn = toast.warn;
        icon = warningIcon;
        title = "Warning";
        break;
      case "info":
        notifyFn = toast.info;
        icon = infoIcon;
        title = "Information";
        break;
      default:
        notifyFn = toast;
        break;
    }

    notifyFn(
      <>
        <strong className="title">{title}</strong>
        {message}
      </>,
      {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        icon: <img src={icon} alt="" />,
        autoClose: 3000,
        draggable: false,
      }
    );
  };

  return { notify };
};

export default useNotification;
