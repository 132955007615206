import React from "react";
import { useNavigate } from "react-router-dom";
import { CollaborationStatus } from "../../constants";
import useAuth from "../../hooks/useAuth";
import Button from "../Button";

function CollaborateButton({ communityRequestData }) {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const isMyOrg =
    communityRequestData.organization?.slug === auth?.user?.organization.slug;

  const communityRequestId = communityRequestData.slug;
  const isPending =
    communityRequestData.collaboration_status === CollaborationStatus.PENDING;
  const label = isPending ? "Pending" : "Collaborate";
  return (
    <>
      {!isMyOrg && (
        <Button
          title={label}
          variant="primary"
          className={isPending ? "pending" : "open"}
          disabled={isPending}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/community-requests/${communityRequestId}`);
          }}>
          {label}
        </Button>
      )}
    </>
  );
}

export default CollaborateButton;
