import { useNavigate } from "react-router-dom";
import useAuth from "./useAuth";

function useLogout() {
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const logout = () => {
    setAuth();
    localStorage.setItem("auth", false);
    navigate("/auth/login");
    navigate(0);
  };
  return logout;
}

export default useLogout;
