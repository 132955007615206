import "./CommunityRequestDetails.scss";

import React, { useEffect, useState } from "react";
import { Col, Row, Image } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAcceptAssets,
  useFetchCommunityRequest,
  useRejectAssets,
} from "../../api/communityRequests";
import CommunityRequestAssetImages from "../../components/community-request/CommunityRequestAssetImages";

import { getAssetPublicUrl, isCollaborator } from "../../utils";
import RejectAssetsModal from "../../components/modals/RejectAssetsModal";
import Button from "../../components/Button";
import useNotification from "../../hooks/useNotification";
import useAuth from "../../hooks/useAuth";
import { CommunityRequestAssetStatus } from "../../constants";

function AcceptCommunityRequestAssetsQrView() {
  const [showRejectAssetsModal, setShowRejectAssetsModal] = useState(false);
  const navigate = useNavigate();
  const { notify } = useNotification();
  const { auth } = useAuth();
  let { id } = useParams();

  const { data: communityRequestData } = useFetchCommunityRequest(id);
  const acceptAssetsMutation = useAcceptAssets();
  const rejectAssetsMutation = useRejectAssets();

  useEffect(() => {
    if (
      communityRequestData?.slug &&
      (!isCollaborator(communityRequestData, auth?.user) ||
        communityRequestData?.collaboration?.asset_package_status !==
          CommunityRequestAssetStatus.COMMUNITY_REQUEST_ASSETS_ACTIVITY_RELEASED)
    ) {
      navigate("/dashboard");
    }
  }, [auth?.user, communityRequestData, navigate]);

  const asset = communityRequestData?.asset_packages[0];
  /*
    TODO: remove this when the API is updated to return the correct public URL
  */
  const assetImages = communityRequestData?.asset_packages[0]?.files?.map(
    (file) => ({
      url: getAssetPublicUrl(file),
    })
  );

  const acceptAssets = async () => {
    try {
      await acceptAssetsMutation.mutateAsync(communityRequestData?.slug);
      notify("success", "Lorem Ipsum");
      navigate(`/community-requests/${communityRequestData?.slug}`);
    } catch (error) {}
  };
  const rejectAssets = async () => {
    try {
      await rejectAssetsMutation.mutateAsync(communityRequestData?.slug);
      notify("info", "Lorem Ipsum");
      navigate(
        `/community-requests/${communityRequestData?.slug}/incident-reports/new`
      );
    } catch (error) {}
  };

  return (
    <>
      {isCollaborator(communityRequestData, auth?.user) && (
        <main>
          <div className="community-request-details pt-2">
            <Row className="header justify-content-center flex-wrap">
              <Col className="text-center">
                <Image
                  src={getAssetPublicUrl(communityRequestData?.creator?.logo)}
                  style={{
                    height: 150,
                    width: 150,
                    objectFit: "cover",
                  }}
                  className="rounded-circle"
                />
                <h1 className="pt-3 pb-0">{communityRequestData?.title}</h1>
                <strong className="community-request-id">
                  Community Request ID:{" "}
                  <span>{communityRequestData?.slug}</span>
                </strong>
                <p className="fw-bold pt-4">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
                <div className="community-request-action py-3">
                  <Button
                    variant="success"
                    loading={acceptAssetsMutation.isLoading}
                    disabled={acceptAssetsMutation.isLoading}
                    onClick={acceptAssets}>
                    Accept Assets
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => setShowRejectAssetsModal(true)}
                    className="d-block text-black fw-bold fs-6 mt-2 mx-auto">
                    Reject Assets
                  </Button>
                </div>
              </Col>
            </Row>
            <Row
              className="d-flex justify-content-between m-auto mt-5"
              style={{ width: 450 }}>
              <Col xs={4} className="ms-5">
                <>
                  <Image
                    src={getAssetPublicUrl(communityRequestData?.creator?.logo)}
                    className="logo"
                  />
                  <dt>Creator</dt>
                  <dd>
                    {communityRequestData?.creator?.name}
                    <br />
                    ID: {communityRequestData?.creator?.id}
                  </dd>
                  <dt>Pick Up</dt>
                  <dd>{communityRequestData?.pickup_complete_address}</dd>
                  <dt>Phone</dt>
                  <dd>{communityRequestData?.creator?.phone}</dd>
                  <dt>Asset Type</dt>
                  <dd>{asset?.category.name}</dd>
                  <dt>Pieces</dt>
                  <dd>{asset?.quantity}</dd>
                </>
              </Col>
              <Col xs={4} className="">
                <>
                  <Image
                    src={getAssetPublicUrl(
                      communityRequestData?.collaborator?.logo
                    )}
                    className="logo"
                  />
                  <dt>Collaborator</dt>
                  <dd>
                    {communityRequestData?.collaborator?.name}
                    <br />
                    ID: {communityRequestData?.collaborator?.id}
                  </dd>
                  <dt>Drop Off</dt>
                  <dd>{communityRequestData?.dropoff_complete_address}</dd>
                  <dt>Phone</dt>
                  <dd>{communityRequestData?.collaborator?.phone}</dd>
                  <dt>Date Time</dt>
                  <dd>{communityRequestData?.created_at}</dd>
                  <dt>Weight</dt>
                  <dd>{asset?.weight}</dd>
                </>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="d-flex flex-column align-items-center mt-4">
                  <CommunityRequestAssetImages
                    images={assetImages ?? []}
                    noPreview={true}
                  />
                </div>
              </Col>
            </Row>
            <RejectAssetsModal
              show={showRejectAssetsModal}
              setShow={setShowRejectAssetsModal}
              onConfirm={rejectAssets}
              loading={rejectAssetsMutation.isLoading}
            />
          </div>
        </main>
      )}
    </>
  );
}

export default AcceptCommunityRequestAssetsQrView;
