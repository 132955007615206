import React from "react";
import DataGrid from "../components/DataGrid";
import OrgHeader from "../components/navigation/OrgHeader";

function Messages() {
  return (
    <>
      <OrgHeader />
      <h3 className="mb-0">Messages</h3>
      <DataGrid comingSoon />
    </>
  );
}

export default Messages;
