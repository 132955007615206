import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import OrgHeader from "../components/navigation/OrgHeader";
import SettingsNav from "../components/SettingsNav";

function SettingsLayout() {
  const location = useLocation();
  const isPreview = location.state?.preview;
  return (
    <>
      <OrgHeader />
      {!isPreview && <SettingsNav />}
      <Outlet />
    </>
  );
}

export default SettingsLayout;
