import "./SettingsNav.scss";

import React from "react";
import { Col, Nav, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";

function SettingsNav() {
  return (
    <>
      <Row className="justify-content-center">
        <h1>Settings</h1>
        <Col md={7}>
          <div className="settings-nav">
            <Row>
              <Col className="text-center">
                <Nav.Link
                  eventKey="5"
                  as={NavLink}
                  className={({ isActive }) => (isActive ? "active-style" : "")}
                  to="/settings/events">
                  Event Listings
                </Nav.Link>
              </Col>
              <Col className="text-center">
                <Nav.Link
                  eventKey="5"
                  as={NavLink}
                  className={({ isActive }) => (isActive ? "active-style" : "")}
                  to={`/settings/my-profile`}>
                  User Profile
                </Nav.Link>
              </Col>
              <Col className="text-center">
                <Nav.Link
                  eventKey="5"
                  as={NavLink}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to={`/settings/org-profile`}>
                  Organization Profile
                </Nav.Link>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default SettingsNav;
