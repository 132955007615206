import { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import DataGrid from "../../components/DataGrid";
import OrgHeader from "../../components/navigation/OrgHeader";
import OrgBadge from "../../components/OrgBadge";
import { useFetchNetworkOrganizations } from "../../api/organizations";

function MyNetwork() {
  const [{ pageIndex }, setPagination] = useState({
    pageIndex: 0,
  });
  const { isLoading, data } = useFetchNetworkOrganizations(pageIndex);
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        header: "Organization",
        accessorKey: "org",
        cell: ({ cell }) => (
          <>
            <OrgBadge org={cell.row.original} mini />
          </>
        ),
      },
      { header: "Industry", accessorKey: "industry" },
      { header: "Location", accessorKey: "location" },
      { header: "Website", accessorKey: "website" },
      {
        header: "collaborations",
        accessorKey: "collaborations",
      },
    ],
    []
  );

  return (
    <>
      <OrgHeader />
      <h3 className="mb-0">My Network</h3>
      <p className="subheading">
        On this page you will find all of the organizations you are subscribed
        to.
      </p>

      <DataGrid
        columns={columns}
        onRowClick={(e, row) => navigate(`/organization/${row.slug}`)}
        isLoading={isLoading}
        data={data?.result?.data ?? []}
        pageIndex={pageIndex}
        setPagination={setPagination}
        pageCount={data?.result?.last_page}
      />
    </>
  );
}

export default MyNetwork;
