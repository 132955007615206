import Button from "../Button";
import ContentModal from "./ContentModal";

function RejectAssetsModal({
  show,
  setShow,
  onConfirm,
  onSecondaryAction,
  loading,
}) {
  return (
    <ContentModal show={show} setShow={setShow} className="content-modal">
      <div className="header">
        <h4 className="d-flex align-items-center gap-3">
          <span>Reject Assets</span>
          <span className="warning">This cannot be undone</span>
        </h4>
      </div>
      <div className="content">
        <p>
          If you are rejecting the assets, due to damage or partiality, please
          fill out an incident report form to document why. Please note, once
          the assets are rejected this cannot be undone.
        </p>
        <div className="actions d-flex align-content-center justify-content-end gap-3">
          <button className="cancel" onClick={() => onSecondaryAction()}>
            Incident Report
          </button>
          <Button variant="danger" onClick={onConfirm} loading={loading}>
            Reject Assets
          </Button>
        </div>
      </div>
    </ContentModal>
  );
}

export default RejectAssetsModal;
