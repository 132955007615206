import "./TopBar.scss";

import React from "react";
import LogoutButton from "../auth/LogoutButton";
import SearchBox from "../SearchBox";
import bellIcon from "../../assets/images/icons/bell.png";

function TopBar() {
  return (
    <>
      <div className="d-flex justify-content-between topbar">
        <div className="d-flex justify-content-start">
          {/* <SearchBox /> */}
        </div>
        <div className="d-flex justify-content-end gap-5">
          <div className="show-notifications">
            <img src={bellIcon} alt="notifications icon" />
          </div>
          <LogoutButton className="logout" />
        </div>
      </div>
    </>
  );
}

export default TopBar;
