import React from "react";
import { ListGroup } from "react-bootstrap";
import {
  CheckCircleFill,
  PersonCircle,
  BellFill,
  GearFill,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";

export default function SuccessfulRegistration() {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className="w-100" style={{ maxWidth: "400px" }}>
        <p className="text-center fs-6 mb-4">
          <span className="d-block fs-3">Thank you for joining us.</span> Your
          account has been created successfully.
        </p>
        <div className="d-flex justify-content-center align-items-center gap-2 my-5">
          <div>
            <CheckCircleFill className="text-success" size={32} />
          </div>
          <h2 className="fs-5 p-0 m-0">Registration Successful</h2>
        </div>
        <div>
          <p className="text-center fs-6">
            <span className="fs-5">We look forward to meeting you soon.</span>{" "}
            <br />
            <Link
              to="https://calendly.com/shawnaruff/60-min-meeting"
              target="_blank">
              Please book an onboard meeting for you organization.
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
