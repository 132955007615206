import React from "react";
import { useParams } from "react-router-dom";
import { useFetchCommunityRequest } from "../../api/communityRequests";
import Loading from "../../components/Loading";
import CommunityRequestDetails from "./CommunityRequestDetails";
import OpenCommunityRequest from "./OpenCommunityRequest";

function ViewCommunityRequest() {
  let { slug: id } = useParams();
  const { isLoading, data: communityRequestData } =
    useFetchCommunityRequest(id);
  if (isLoading) return <Loading />;
  return communityRequestData?.approved_collaborator ? (
    <CommunityRequestDetails communityRequestData={communityRequestData} />
  ) : (
    <OpenCommunityRequest communityRequestData={communityRequestData} />
  );
}

export default ViewCommunityRequest;
