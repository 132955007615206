import "./OrgBadge.scss";
import chatIcon from "../assets/images/icons/multi_bubble_light.png";
import orgLogoPlaceholder from "../assets/images/logo_placeholder.gif";

import React from "react";
import { Image } from "react-bootstrap";
import Button from "./Button";
import { getAssetPublicUrl } from "../utils";

function OrgBadge({ org, mini, noLogo, isCreator, onChatClick, onClick }) {
  if (!org) return null;
  let { slug, name, logo } = org;
  const dimension = isCreator ? 100 : 45;
  return (
    <div
      className={`clearfix org-badge ${mini ? "mini" : ""} ${
        noLogo ? "no-logo" : ""
      }`}
      style={onClick && { cursor: "pointer" }}
      onClick={() => onClick && onClick()}>
      {!noLogo && (
        <Image
          src={getAssetPublicUrl(logo)}
          width={dimension}
          height={dimension}
          style={{ objectFit: "contain" }}
          className="rounded-circle float-start me-3"
          onError={({ currentTarget }) => {
            currentTarget.onError = null;
            currentTarget.src = orgLogoPlaceholder;
          }}
        />
      )}
      <div className={`float-start`}>
        {isCreator ? (
          <>
            <div className="creator ps-3">
              <h4>Creator</h4>
              <div className="name">{name}</div>
              <div className="id">ID: {slug}</div>
              <Button variant="primary" className="mt-3" onClick={onChatClick}>
                <Image src={chatIcon} width={25} className="me-3" /> Chat with
                Creator
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="name">{name}</div>
            {!mini && <div className="id">ID: {slug}</div>}
          </>
        )}
      </div>
    </div>
  );
}

export default OrgBadge;
