import { useNavigate, useParams } from "react-router";
import { CommunityRequestStatus } from "../../constants";
import CommunityRequestForm from "../../forms/CommunityRequestForm";
import Loading from "../../components/Loading";
import { useFetchCommunityRequest } from "../../api/communityRequests";

function EditCommunityRequest() {
  let { slug: id } = useParams();
  const navigate = useNavigate();
  const { data: communityRequestData, isInitialLoading } =
    useFetchCommunityRequest(id);
  if (
    communityRequestData?.status !==
    CommunityRequestStatus.COMMUNITY_REQUEST_STATUS_DRAFT
  ) {
    navigate(`/community-requests/${communityRequestData?.slug}`);
  }

  return (
    <>
      {isInitialLoading ? (
        <Loading />
      ) : (
        <CommunityRequestForm
          communityRequest={communityRequestData}
          updateMode={
            communityRequestData &&
            communityRequestData.status ===
              CommunityRequestStatus.COMMUNITY_REQUEST_STATUS_DRAFT
          }
        />
      )}
    </>
  );
}

export default EditCommunityRequest;
