import { useState } from "react";

import Loading from "../../components/Loading";
import OrgProfileForm from "../../forms/settings/OrgProfileForm";
import OrgProfile from "./OrgProfile";
import useAuth from "../../hooks/useAuth";
import { useFetchOrganization } from "../../api/organizations";
import { useLocation } from "react-router-dom";

function EditOrgProfile() {
  let [profileData, setProfileData] = useState(null);
  const { auth } = useAuth();
  const { isLoading, data } = useFetchOrganization(
    auth?.user?.organization?.slug
  );
  const location = useLocation();
  const isPreview = location.state?.preview;
  const fromPreview = location.state?.fromPreview;
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : isPreview ? (
        <OrgProfile profileData={profileData} preview={isPreview} />
      ) : (
        <OrgProfileForm
          profileData={fromPreview ? profileData : data?.result}
          setProfileData={setProfileData}
          preview={isPreview}
          fromPreview={fromPreview}
        />
      )}
    </>
  );
}

export default EditOrgProfile;
