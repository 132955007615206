import { useState } from "react";

const useForm = (initialValues, callback) => {
  const [formData, setFormData] = useState(initialValues);
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    setValidated(true);
    event.preventDefault();

    if (!form.checkValidity()) {
      event.stopPropagation();
      const firstInvalidElement = form.querySelector(":invalid:first-of-type");
      if (firstInvalidElement) {
        firstInvalidElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        setTimeout(() => {
          firstInvalidElement.reportValidity();
        }, 1000);
      }
    } else {
      callback(event);
      resetForm(event);
    }
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const resetForm = (event) => {
    event.target.reset();
    setValidated(false);
  };

  return {
    formData,
    setFormData,
    validated,
    handleSubmit,
    handleChange,
  };
};

export default useForm;
