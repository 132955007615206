import React from "react";
import { useParams } from "react-router-dom";
import OrgProfile from "./OrgProfile";

import { useFetchOrganization } from "../../api/organizations";
import OrgHeader from "../../components/navigation/OrgHeader";

function Org() {
  let { slug } = useParams();
  const { data } = useFetchOrganization(slug);

  return (
    <>
      <OrgHeader />
      {data && <OrgProfile preview={false} profileData={data?.result} />}
    </>
  );
}

export default Org;
