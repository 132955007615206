import React from "react";
import "./LegalPolicies.scss";

function LegalPolicies() {
  return (
    <div className="legal-policies">
      <p className="c1">
        <span className="c11 c16">Legal Policies</span>
      </p>
      <p className="c1">
        <span className="c11 c14">Terms &amp; Agreements</span>
      </p>
      <p className="c8">
        <span className="c5">November 2024</span>
      </p>
      <p className="c0">
        <span className="c5"></span>
      </p>
      <p className="c1">
        <span className="c6">Privacy Policy</span>
      </p>
      <p className="c1">
        <span className="c2">
          We are Impart Inc., a Washington corporation (Impart), and the owner
          and operator of the website [www.impactpartnersnetwork.com] and all of
          its subdomains (collectively, the Website), the Impart software
          platform accessed via the Website (the Platform), including any
          content, functionality, and services offered on or through the Website
          or Platform (collectively, the Services) (Website, Platform, and
          Services shall be collectively referred to herein as the Technology).
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          This Impart Privacy Policy (Policy) is entered into by and between you
          and Impart, and governs the use, processing, and disclosure of your
          Personal Information (as defined in Section 2.1 below) on or via the
          Technology. Impart respects your privacy and as a part of
          Impart&rsquo;s mission to provide you effective services, Impart
          collects, processes, stores, and discloses information you provide on
          the Technology under specific conditions, and in accordance with
          applicable privacy laws and regulations, including without limitation
          California&rsquo;s Consumer Privacy Act (CCPA; California Civil Code
          sections 22575-22579). This Policy describes what information Impart
          collects from you, how it is used and secured, and when and to whom it
          may be disclosed. Please take a few minutes to review this Policy.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c6">1. Consent</span>
      </p>
      <p className="c0">
        <span className="c5"></span>
      </p>
      <p className="c1">
        <span className="c2">
          The Technology is not intended to be used in the European Union (EU),
          or by any EU citizens. If you do not consent to the terms and
          conditions of this Policy, you should not use any of the Technology,
          and notify Impart of your refusal to consent to this Policy pursuant
          to Section 10.1 of this Policy should any Personal Information already
          be collected by Impart or its agents.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c6"></span>
      </p>
      <p className="c1">
        <span className="c6">2. Personal Information</span>
      </p>
      <p className="c0">
        <span className="c5"></span>
      </p>
      <p className="c1">
        <span className="c2">
          2.1 Collected Personal Information Impart and its designated
          third-party agents may collect the following forms of personally
          identifiable information and content you provide on the Technology, or
          which you permit Impart to collect from third-party websites and
          applications (collectively, Personal Information): (a) personal names;
          (b) phone numbers; (c) billing addresses; (d) e-mail addresses; (e)
          age-related and demographic information; (f) personal identification
          numbers; (g) payment information (e.g. credit card numbers, bank
          account numbers, etc.); (h) job titles and positions; (i) comments
          made on third-party websites and applications; (j) device
          identification information; (k) geolocation data; (l) preferred
          language and country information; (m) communications sent via the
          Technology; (n) information on your interactions on Technology, and
          other Technology users; and (o) other reasonably relevant information
          pertaining to your use of the Technology including without limitation
          your Internet protocol addresses, Technology account usernames and
          passwords, and standard web log information.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          2.2 Your Information Obligations Impart requires that you only provide
          Personal Information to Impart, whether directly or indirectly, that
          is up-to-date and accurate, and that you inform Impart of any changes
          or corrections to such Personal Information. You also assure Impart
          that you have the full authority and right to submit such Personal
          Information to Impart, and to use such Personal Information as
          detailed in this Policy.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          2.3 Personal Information Amendments Impart requires that you update
          and correct any Personal Information you provide to Impart via the
          Technology. Should you be made aware that any of the Personal
          Information you provide to Impart is incorrect or outdated, please
          notify Impart of such events and provide Impart corrected Personal
          Information via the contact information provided in Section 12 of this
          Policy.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c6"></span>
      </p>
      <p className="c1">
        <span className="c6">3. Cookies </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          When you register a Website or Platform account or utilize any of the
          Technology, Impart may assign you a unique identifier and send such
          information to your computer or device hard drive in the form of a
          cookie, which is a very small bit of code. This code is uniquely
          yours. It is used to assist you in utilizing the Technology. If you do
          not wish to have any such cookies on your devices, you may turn them
          off at any time by modifying your device&rsquo;s Internet browser
          settings. However, by disabling cookies on your device, you may be
          prohibited from fully utilizing the Technology.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c6">4. Use of Your Personal Information</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          &zwj;Impart may use your Personal Information for the following
          purposes:{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          4.1 Provide and Promote the Technology: Impart may use your Personal
          Information to provide and promote the Technology including without
          limitation Technology goods and service offerings, promotions from
          Impart and other parties based on your preferences made via the
          Technology, and Impart service payment functionalities, if any;
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          4.2 User Research: Impart may use your Personal Information in an
          anonymous manner for internal customer research, to learn more about
          our users and their technological needs.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          4.3 Encourage and Ensure Safe Interactions Between Technology Users:
          Impart may use your Personal Information to help improve the safety
          and reliability of the Technology, including detecting, preventing,
          and responding to fraud, abuse, security risks, and technical issues
          that could harm Impart, other Technology users, or the general public.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          4.4 Resolve Disputes and Troubleshoot Problems: Impart may use your
          Personal Information to investigate or resolve any Technology-related
          disputes or problems;
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          4.5 Enforcement: Impart may use your Personal Information to enforce
          this Policy or any other agreement associated with the Technology;
          &zwj;
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          4.6 Customize, Measure, and Improve Website and Service User
          Experience: Impart may use your Personal Information for analytical
          and measuring purposes to understand how the Technology is used,
          through use of third-party analytics tools;
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          4.7 Communication: Impart uses your Personal Information to interact
          with you directly concerning the Technology, or Impart&rsquo;s goods
          or services.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          4.8 Fulfill Reporting and Legal Requirements: Impart may use your
          Personal Information when requested by applicable governmental
          authorities for reporting obligations and compliance requirements.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c6">5. Disclosure of Personal Information</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          5.1 Information Disclosed Impart may disclose your Personal
          Information to a third party: (a) in furtherance of Impart&rsquo;s
          business operations, including assisting Technology users with
          anonymized user data for research (b) to comply with Impart or its
          designated third-party&rsquo;s legal obligations under applicable law
          or regulation; (c) to enforce Impart&rsquo;s policies under this
          Policy, or any other policy or agreement you may be subject to
          pertaining to the Technology; (d) to respond to claims that your
          Personal Information or other information or content violates another
          party&rsquo;s rights; or (e) to protect any party&rsquo;s rights,
          property, or safety.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          5.2 Disclosed Parties In general, Impart currently discloses your
          Personal Information to: (a) its officers and agents subject to the
          terms of this Policy; and (b) third party vendors AWS, MailChimp,
          GoDaddy, Google, and Webflow.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c6"></span>
      </p>
      <p className="c1">
        <span className="c6">
          6. Security and Storage of Your Personal Information
        </span>
      </p>
      <p className="c0">
        <span className="c6"></span>
      </p>
      <p className="c1">
        <span className="c2">
          &zwj;The security of your Personal Information is important to Impart.
          Impart will only keep your Personal Information for the purposes
          detailed in Section 4 of this Policy, as long as is required by law,
          or as is relevant for the purposes for which it is collected. Your
          Personal Information will be stored in the following formats and
          locations:
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          Impart&rsquo;s AWS cloud servers. Further information about Amazon
          Cloud Services privacy policies and handling of Personal Information
          can be found here.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          Impart and its designated third parties employ generally accepted
          industry standards to store and protect your Personal Information.
          However, as the transmission of information over wireless and wired
          networks cannot be absolutely secure, Impart does not warrant or
          guarantee that your Personal Information will remain permanently
          secure.
        </span>
      </p>
      <p className="c0">
        <span className="c6"></span>
      </p>
      <p className="c1">
        <span className="c6">7. Third-Party Websites and Applications</span>
      </p>
      <p className="c0">
        <span className="c5"></span>
      </p>
      <p className="c1">
        <span className="c2">
          The Technology may contain links to third-party websites and
          applications that are not owned or controlled by Impart. Impart has no
          control over, and assumes no responsibility for, any share
          functionality, content, privacy policies, or practices of any
          third-party website or application. When using any such third-party
          website or application, you are subject to the terms and policies,
          including without limitation personal information security and storage
          policies, of such third-party website or application. By affirmatively
          consenting to this Policy, or by otherwise using the Technology, you
          expressly relieve Impart from any and all liability arising from any
          share functionality or your use of any third-party website accessed
          from the Technology. Impart encourages you to investigate and ask
          questions before you disclose your personally identifiable information
          to any third-party website, application, or service provider.
        </span>
      </p>
      <p className="c0">
        <span className="c6"></span>
      </p>
      <p className="c1">
        <span className="c6">8. Child Protection - ALL USERS MUST BE 18+</span>
      </p>
      <p className="c0">
        <span className="c5"></span>
      </p>
      <p className="c1">
        <span className="c2">
          Impart is committed to protecting the privacy of children who use the
          Technology, and encourages parents and guardians to talk to their
          children about safe and responsible use of their information and
          content on the Internet. To ensure the Technology&rsquo;s compliance
          with the Children&rsquo;s Online Privacy Protection Act (COPPA; 15
          U.S.C. &sect; 91 et seq.), none of the Technology: (a) is made
          available to any person under 18 years of age; (b) publishes
          information or content from any person under 18 years of age; and (c)
          is targeted to any child under 18 years of age. Any person under 18
          years of age who wishes to access or use the Technology must obtain
          their parent or guardian&rsquo;s prior consent prior to any such use.
        </span>
      </p>
      <p className="c0">
        <span className="c6"></span>
      </p>
      <p className="c1">
        <span className="c6">9. Privacy Disclosure Reporting</span>
      </p>
      <p className="c0">
        <span className="c5"></span>
      </p>
      <p className="c1">
        <span className="c2">
          You may be entitled to request a report concerning the collection,
          use, and storage of your Personal Information (Privacy Report) that:{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (a) confirms whether your Personal Information has been processed, and
          if so, the purpose for such processing;{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (b) the categories and types of Personal Information collected and
          processed;{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (c) the providers and recipients of such Personal Information; and
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (d) the envisaged period for which your Personal Information will be
          stored, or, if not possible, the criteria used to determine that
          period.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          If you would like a copy of your Privacy Report, you may do so by
          contacting Impart (Attn: Privacy Rights Report Request) pursuant to
          Section 12 of this Policy.
        </span>
      </p>
      <p className="c0">
        <span className="c6"></span>
      </p>
      <p className="c1">
        <span className="c6">
          10. User Modifications to Consent and Use of Personal Information
        </span>
      </p>
      <p className="c0">
        <span className="c5"></span>
      </p>
      <p className="c1">
        <span className="c2">
          10.1 Consent Modifications Should you desire to withdraw your consent
          to your disclosure of Personal Information to Impart, or
          Impart&rsquo;s use of such Personal Information as provided under this
          Policy, you may do so by notifying Impart of the same via the contact
          information provided in Section 12 of this Policy titled Attn:
          Personal Information Consent Modification.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          10.2 Right to be Forgotten Technology users also have the right to
          have their Personal Information erased from Impart&rsquo;s and its
          agents&rsquo; systems. Should you wish to elect to have your Personal
          Information erased from any of such systems, you may do so by
          contacting Impart titled Attn: Right to be Forgotten pursuant to
          Section 12 of this Policy, detailing who you are, why you wish for
          your Personal Information to be erased, and that you elect to have
          your Personal Information erased.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          10.3 Data Portability You also have the right to request and receive a
          portable version of all of your Personal Information collected and
          used by Impart and its agents. Should you wish to receive a portable
          version of your collected Personal Information, you may do so by
          contacting Impart titled Attn: Data Portability pursuant to Section 12
          of this Policy, detailing who you are, and including a detailed
          request for a portable version of your Personal Information.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          10.4 Reporting of Privacy Complaint As a part of Impart&rsquo;s
          efforts to ensure your Personal Information is kept as confidential as
          possible, and used, processed, and transferred only in accordance with
          this Policy, Impart will reasonably attempt to notify you of any
          breach or unauthorized disclosure of your Personal Information to any
          third party. Conversely, should you become aware of any violation or
          breach of your Personal Information by Impart or any other party, you
          may lodge a complaint to the following supervisory authorities:
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          United States Authority: Federal Trade Commission
        </span>
      </p>
      <p className="c1">
        <span className="c2">How to File: Via Phone or Online</span>
      </p>
      <p className="c1">
        <span className="c2">Phone: +1-877-FTC-HELP (+1-877-382-4357)</span>
      </p>
      <p className="c1">
        <span className="c2">Online: www.ftccomplaintassistant.gov </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          European Union Authority: EU Member State Data Protection Authority
          (DPA) Where You Reside (links to EU Member state data protection
          authorities can be found here) How to File Varies by EU Member State
          DPA &mdash; Please refer to your specific DPA here
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          United Kingdom Authority: Information Commissioner&rsquo;s Office
        </span>
      </p>
      <p className="c1">
        <span className="c2">How to File: Via Phone, Online, or E-Mail</span>
      </p>
      <p className="c1">
        <span className="c2">Phone: +44 0303 123-1113</span>
      </p>
      <p className="c1">
        <span className="c2">
          Online: www.gov.uk/data-protection/make-a-complaint
        </span>
      </p>
      <p className="c1">
        <span className="c2">E-Mail: icocasework@ico.org.uk</span>
      </p>
      <p className="c0">
        <span className="c6"></span>
      </p>
      <p className="c1">
        <span className="c6">11. Policy Changes</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          &zwj;Periodically, Impart may update this Policy. Impart may post
          notifications of updates to this Policy on the Website or Platform,
          through the Services, or via e-mail to you and other Technology users.
          Changes made to this Policy will take effect immediately. If you do
          not agree to changes to this Policy, you must discontinue your use of
          the Technology, and notify Impart of the same pursuant to Section 10.1
          of this Policy. Your access or use of the Technology after such Policy
          changes will not be permitted unless you provide affirmative consent
          to such Policy changes via the Technology.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c6">12. Contacting Us</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          If you have any questions regarding this Policy, please contact Impart
          Inc. at:
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">By mail: </span>
      </p>
      <p className="c1">
        <span className="c2">Attn: Privacy Policy Inquiry</span>
      </p>
      <p className="c1">
        <span className="c2">Impart Inc.</span>
      </p>
      <p className="c1">
        <span className="c2">600 1st Avenue, Suite 103</span>
      </p>
      <p className="c1">
        <span className="c2">Seattle, WA 98103</span>
      </p>
      <p className="c1">
        <span className="c7">By email: </span>
        <span className="c15 c7">
          <a className="c12" href="mailto:support@impactpartnersnetwork.com">
            support@impactpartnersnetwork.com
          </a>
        </span>
      </p>
      <p className="c0">
        <span className="c4"></span>
      </p>
      <p className="c0">
        <span className="c6"></span>
      </p>
      <p className="c0">
        <span className="c6"></span>
      </p>
      <p className="c0">
        <span className="c6"></span>
      </p>
      <p className="c0">
        <span className="c6"></span>
      </p>
      <p className="c1">
        <span className="c6">Impart Inc. Software as a Service Agreement</span>
      </p>
      <p className="c0">
        <span className="c7 c10"></span>
      </p>
      <p className="c1">
        <span className="c2">
          This Software as a Service Agreement (this &ldquo;Agreement&rdquo;),
          effective as of October
        </span>
      </p>
      <p className="c1">
        <span className="c7">
          1st, 2024 (the &ldquo;Effective Date&rdquo;), is by and between Impart
          Inc., a Washington State corporation with offices located at 600 1st
          Avenue, Suite 103, Seattle, WA 98104 (&ldquo;Provider&rdquo;) and{" "}
        </span>
        <span className="c7">Your Organization, (&ldquo;Customer&rdquo;)</span>
        <span className="c2">
          . Provider and Customer may be referred to herein collectively as the
          &ldquo;Parties&rdquo;, or individually as a &ldquo;Party.&rdquo;
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          WHEREAS, Customer desires to access the Services, and Provider desires
          to
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          provide Customer access to the Services, subject to the terms and
          conditions of this
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Agreement. By signing up for and signing on to the platform and
          accessing the Services, you are agreeing to this Agreement.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          NOW, THEREFORE, in consideration of the mutual covenants, terms, and
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          conditions set forth herein, and for other good and valuable
          consideration, the receipt
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          and sufficiency of which are hereby acknowledged, the Parties agree as
          follows:
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c6">1. Definitions</span>
      </p>
      <p className="c1">
        <span className="c2">
          (a) &ldquo;Aggregated Statistics&rdquo; means data and information
          related to
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Customer&rsquo;s use of the Services that is used by Provider in an
          aggregate and
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          anonymized manner, including to compile statistical and performance
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          information related to the provision and operation of the Services.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (b) &ldquo;Authorized Users&rdquo; collectively refer Customer&rsquo;s
          employees, consultants,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          contractors, and agents (i) who are authorized by Customer to access
          and use
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          the Services solely on behalf of Customer under the rights granted to
          Customer
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          pursuant to this Agreement and (ii) for whom access to the Services
          has been
        </span>
      </p>
      <p className="c1">
        <span className="c2">purchased hereunder.</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (c) &ldquo;Documentation&rdquo; means Provider&rsquo;s standard user
          manuals,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          handbooks, and guides relating to the Services provided by Provider to
          Customer
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          either electronically or in hard copy form and any end user
          documentation
        </span>
      </p>
      <p className="c1">
        <span className="c7">relating to the Services available at </span>
        <span className="c7 c15">
          <a className="c12" href="https://www.impactpartnersnetwork.com">
            https://www.impactpartnersnetwork.com
          </a>
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (d) &quot;Intellectual Property Rights&quot; collectively refers to
          any and all intellectual
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          property and/or proprietary rights worldwide, including without
          limitation any
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          and all copyrights, trademarks, service marks, trade secrets, patents,
          patent
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          applications, contract rights and any and all other legal rights
          protecting
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          intangible proprietary information, and all registrations, initial
          applications,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          renewals, extensions, continuations, divisions or reissues thereof now
          or
        </span>
      </p>
      <p className="c1">
        <span className="c2">hereafter in force.</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (e) &ldquo;Losses&rdquo; means all damages, awards, judgments,
          assessments,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          fines, penalties, charges, costs and expenses and other payments
          (including
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          punitive or consequential damages) however suffered or characterized,
          all
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          interest thereon, all costs and expenses of investigating any claim,
          lawsuit or
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          arbitration and any appeal therefrom, all reasonable attorneys&rsquo;,
          accountants&rsquo; and
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          expert witness&rsquo; fees incurred in connection therewith, whether
          or not such claim,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          lawsuit or arbitration is ultimately defeated and all amounts paid
          incident to any
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          compromise or settlement of any such claim, lawsuit or arbitration.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (f) &ldquo;Provider Materials&rdquo; means the Services, the
          Documentation, any
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          content, data or other information, and any and all improvements,
          modifications,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          enhancements and/or updates to any of the aforementioned materials,
          that
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Provider makes available to Customer or any Authorized User in
          connection with
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          this Agreement. For the avoidance of doubt, Provider Materials include
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Aggregated Statistics and any information, data, or other content
          derived from
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Provider&rsquo;s monitoring of Customer&rsquo;s access to or use of
          the Services, but does not
        </span>
      </p>
      <p className="c1">
        <span className="c2">include User Data.</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (g) &ldquo;Services&rdquo; means the software-as-a-service offering
          described in
        </span>
      </p>
      <p className="c1">
        <span className="c2">Exhibit A.</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (h) &ldquo;User Data&rdquo; means, other than Aggregated Statistics,
          information,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          data, and other content, in any form or medium, that is submitted,
          posted, or
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          otherwise transmitted by or on behalf of Customer or an Authorized
          User through
        </span>
      </p>
      <p className="c1">
        <span className="c2">the Services.</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c6">2. Access and Use</span>
      </p>
      <p className="c1">
        <span className="c2">
          (a) Provision of Access. Subject to and conditioned on
          Customer&rsquo;s
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          payment of Fees and compliance with all other terms and conditions of
          this
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Agreement, Provider hereby grants to Customer a non-exclusive,
          non-transferable
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          (except in compliance with Section 12(g)) right to access and use the
          Services
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          during the Term, solely for use by Authorized Users and solely on
          behalf of
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Customer in accordance with the terms and conditions herein. Such use
          will be
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          subject to Provider&rsquo;s Terms of Service which are available at:
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          https://impactpartnersnetwork.com/terms-of-service and incorporated
          herein by reference, as may be amended by Provider from time to time
          (&ldquo;Terms of Service&rdquo;). In instances of conflict between the
          hyperlinked terms of service and this Agreement, the terms of this
          Agreement shall control unless the updated or amended Terms of Service
          have been
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          expressly consented to in writing by Customer. Section 12 of the
          hyperlink terms
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          is struck in its entirety and not effective in this Agreement. This
          Agreement is{" "}
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          subject to mandatory arbitration. Provider shall provide to Customer
          and the
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Authorized Users the necessary passwords and network links or
          connections to
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          allow Customer to access the Services. The total number of Authorized
          Users will
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          not exceed the number set forth in Exhibit A, except as expressly
          agreed to in
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          writing by the Parties and subject to any appropriate adjustment of
          the Fees
        </span>
      </p>
      <p className="c1">
        <span className="c2">payable hereunder.</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (b) Documentation License. Subject to the terms and conditions
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          contained in this Agreement, Provider hereby grants to Customer a
          non-exclusive, non-sublicenseable, non-transferable (except in
          compliance with
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Section 12(g)) license to use the Documentation during the Term solely
          for
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Customer&rsquo;s internal business purposes in connection with its use
          of the Services.
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          (c) Use Restrictions. Customer shall not use the Services for any
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          purposes beyond the scope of the access granted in this Agreement.
          Customer
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          shall not at any time, directly or indirectly, and shall not permit
          any Authorized
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          User to: (i) copy, modify, or create derivative works of the Services
          or
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Documentation, in whole or in part; (ii) rent, lease, lend, sell,
          license, sublicense,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          assign, distribute, publish, transfer, or otherwise make available the
          Services or
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Documentation; (iii) reverse engineer, disassemble, decompile, decode,
          adapt, or
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          otherwise attempt to derive or gain access to any software component
          of the
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Services, in whole or in part; (iv) remove, alter or obscure any
          proprietary notices
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          from the Services or Documentation; (v) use the Services or
          Documentation in
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          any manner or for any purpose that infringes, misappropriates, or
          otherwise
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          violates any intellectual property right or other right of any person,
          or that
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          violates any applicable law; (vi) transmit material containing
          software viruses or
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          other harmful or deleterious computer code, files, scripts, agents, or
          programs;
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          (vii) send any form of duplicative and unsolicited messages; (viii)
          harvest, collect,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          gather or assemble information or data regarding other users without
          their
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          consent; or (ix) transmit or post through the Services unlawful,
          immoral, libelous,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          tortuous, infringing, defamatory, threatening, vulgar, or obscene
          material or
        </span>
      </p>
      <p className="c1">
        <span className="c2">material harmful to minors.</span>
      </p>
      <p className="c1">
        <span className="c2">
          (d) Reservation of Rights. Provider reserves all rights not expressly
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          granted to Customer in this Agreement. Except for the limited rights
          and licenses
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          expressly granted under this Agreement, nothing in this Agreement
          grants, by
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          implication, waiver, estoppel, or otherwise, to Customer or any third
          party any
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Intellectual Property Right or other right, title, or interest in or
          to the Provider
        </span>
      </p>
      <p className="c1">
        <span className="c2">Materials.</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (e) Suspension. Notwithstanding anything to the contrary in this
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Agreement, Provider may temporarily suspend Customer&rsquo;s and any
          Authorized
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          User&rsquo;s access to any portion or all of the Services if: (i)
          Provider reasonably
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          determines that (A) there is a threat or attack on any of the Provider
          Materials;
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (B) Customer&rsquo;s or any Authorized User&rsquo;s use of the
          Provider Materials
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          disrupts or poses a security risk to the Provider Materials or to any
          other
        </span>
      </p>
      <p className="c1">
        <span className="c2">customer or vendor of Provider; </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">(C) Customer, or any Authorized User, is</span>
      </p>
      <p className="c1">
        <span className="c2">
          using the Provider Materials for fraudulent or illegal activities;{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (D) subject to applicable law, Customer has ceased to continue its
          business in the ordinary course, made an assignment for the benefit of
          creditors or similar disposition of its assets, or become the subject
          of any bankruptcy, reorganization, liquidation,
        </span>
      </p>
      <p className="c1">
        <span className="c2">dissolution, or similar proceeding; or </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (E) Provider&rsquo;s provision of the Services to
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Customer or any Authorized User is prohibited by applicable law;{" "}
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          (ii) any vendor of Provider has suspended or terminated
          Provider&rsquo;s access to or use of
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          any third-party services or products required to enable Customer to
          access the
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Services; or (iii) in accordance with Section 5(a) (any such
          suspension described
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          in subclause (i), (ii), or (iii), a &ldquo;Service Suspension&rdquo;).
          Provider shall use
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          commercially reasonable efforts to provide written notice of any
          Service
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Suspension to Customer and to provide updates regarding resumption of
          access
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          to the Services following any Service Suspension. Provider shall use
          commercially
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          reasonable efforts to resume providing access to the Services as soon
          as
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          reasonably possible after the event giving rise to the Service
          Suspension is cured.
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Provider will have no liability for any damage, liabilities, losses
          (including any loss
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          of data or profits), or any other consequences that Customer or any
          Authorized
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          User may incur as a result of a Service Suspension.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (f) Aggregated Statistics. Notwithstanding anything to the contrary in
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          this Agreement, Provider may monitor Customer and any Authorized
          User&rsquo;s use of the Services and collect
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          and compile Aggregated Statistics. As between Provider and Customer,
          all right,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          title, and interest in Aggregated Statistics, and all Intellectual
          Property Rights
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          therein, belong to and are retained solely by Provider. Customer
          acknowledges
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          that Provider may compile Aggregated Statistics based on Customer Data
          input
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          into the Services. Customer agrees that Provider may (i) make
          Aggregated
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Statistics publicly available in compliance with applicable law, and
          (ii) use
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Aggregated Statistics to the extent and in the manner permitted under
          applicable
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          law; provided that such Aggregated Statistics do not identify a
          Customer or
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Customer&rsquo;s Confidential Information. Provider agrees to fully
          adhere to Federal
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Educational Rights and Privacy Act (&ldquo;FERPA&rdquo;) to the extent
          the Aggregated
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Statistics contain Educational Records as defined under FERPA.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (g) Marketing by Provider. Provider may request Customer&rsquo;s
          consent to
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          use Customer&rsquo;s name in marketing and advertising materials in a
          manner
        </span>
      </p>
      <p className="c1">
        <span className="c2">approved by Customer.</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c6">
          3. Customer Responsibilities, Covenants, Representations and
          Warranties
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (a) General. Customer is responsible and liable for all uses of the
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Services and Documentation resulting from access provided by Customer,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          directly or indirectly, whether such access or use is permitted by or
          in violation of
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          this Agreement. Without limiting the generality of the foregoing,
          Customer is
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          responsible for all acts and omissions of Authorized Users, and any
          act or
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          omission by an Authorized User that would constitute a breach of this
          Agreement
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          if taken by Customer will be deemed a breach of this Agreement by
          Customer.
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Customer shall use reasonable efforts to make all Authorized Users
          aware of this
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Agreement&rsquo;s provisions as applicable to such Authorized
          User&rsquo;s use of the
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Services, and shall cause Authorized Users to comply with such
          provisions.
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          (b) Compliance with Law. Customer shall at all times comply with all
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          applicable laws, ordinances, regulations, orders, terms of services,
          and policies in connection with Customer&rsquo;s and the other
          Authorized Users&rsquo; use of the Service hereunder,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          including without limitation all privacy and data protection laws of
          United States
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          European Union and any other jurisdiction applicable to the Customer
          or
        </span>
      </p>
      <p className="c1">
        <span className="c7">Authorized Users. </span>
        <span className="c2">
          Without limiting the generality of the foregoing, Customer shall
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          at all times, at its own expense, obtain and maintain all approvals,
          consents,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          certifications, credentials, authorizations, licenses, and permits
          required in
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          connection with (i) its participation in the Project and (ii)
          Customer&rsquo;s and
        </span>
      </p>
      <p className="c1">
        <span className="c7">Authorized Users&rsquo; use of the Services.</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c11">4. Support.</span>
        <span className="c2">
          &nbsp;The access rights granted hereunder entitles Customer to the
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          support services described in Exhibit B during the Term.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c4">5. Fees and Payment.</span>
      </p>
      <p className="c1">
        <span className="c2">
          (a) Fees. Customer shall pay Provider the fees for Services
          (collectively, the &ldquo;Fees&rdquo;) for the Term as set forth in
          Exhibit A without offset or deduction on or before 30 days after the
        </span>
      </p>
      <p className="c1">
        <span className="c7">
          agreement signed. The Fees during any Renewal Period (
        </span>
        <span className="c3">as hereinafter defined</span>
        <span className="c2">)</span>
      </p>
      <p className="c1">
        <span className="c2">
          shall be adjusted by Provider to reflect the applicable scope of
          services, number
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          of Authorized Users, hubs, and events, and extent of analytics and
          support
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          agreed upon by the Parties for such Renewal Period. The Fees with
          respect to
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          any Renewal Period shall be paid prior to the commencement of such
          Renewal
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Period. Customer shall make all payments hereunder in U.S. Dollars.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          Provider shall have no obligation to make the Service available to
          Customer prior to
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Customer&rsquo;s payment of applicable Fees. If Customer fails to make
          any payment of Fees{" "}
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          when due, without limiting Provider&rsquo;s other rights and remedies:
          (i) Provider may charge
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          interest on the past due amount at the rate of one percent (1.0%) per
          month
        </span>
      </p>
      <p className="c1">
        <span className="c7">calculated daily and compounded monthly</span>
        <span className="c7">
          , or at the highest monthly rate available under applicable law
        </span>
        <span className="c2">
          ; (ii) Customer shall reimburse Provider for all reasonable fees and
          costs incurred by Provider in collecting any late payments or
          interest, including attorneys&rsquo; fees, court costs, and collection
          agency fees; and (iii) if such failure continues for forty-five (45)
          days or more, Provider may suspend Customer&rsquo;s and its Authorized
          Users&rsquo; access to any portion or all of the Services
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          until such amounts are paid in full. If Customer fails to pay accrued
          Fees, Provider shall
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          have the right, in addition to any of its other rights or remedies, to
          suspend the
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Service to Customer and/or any Authorized User, without liability to
          Customer until such amounts are paid in full.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (b) Taxes. All Fees and other amounts payable by Customer under this
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Agreement are exclusive of taxes and similar assessments. Customer is
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          responsible for all sales, use, and excise taxes, and any other
          similar taxes,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          duties, and charges of any kind imposed by any federal, state, or
          local
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          governmental or regulatory authority on any amounts payable by
          Customer
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          hereunder, other than any taxes imposed on Provider&rsquo;s income.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c9">6. Confidential Information</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          From time-to-time during the Term, either Party
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          may disclose or make available to the other Party information about
          its business affairs,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          products, confidential intellectual property, trade secrets,
          third-party confidential
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          information, and other sensitive or proprietary information, whether
          orally or in written,
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          electronic, or other form or media, and whether or not marked,
          designated or otherwise
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          identified as &ldquo;confidential&rdquo; that a reasonable person
          would understand to be
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          confidential (collectively, &ldquo;Confidential Information&rdquo;).
          Confidential Information does not
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          include information that, at the time of disclosure is: (a) generally
          known to the public;
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          (b) already known to the receiving Party at the time of disclosure;
          (c) rightfully obtained
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          by the receiving Party on a non-confidential basis from a third party;
          or (d)
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          independently developed by the receiving Party. The receiving Party
          shall not disclose
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          the disclosing Party&rsquo;s Confidential Information to any person or
          entity, except to the
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          receiving Party&rsquo;s employees who have a need to know the
          Confidential Information for
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          the receiving Party to exercise its rights or perform its obligations
          hereunder.
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Notwithstanding the foregoing, each Party may disclose Confidential
          Information to the
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          limited extent required in order to comply with the order of a court
          or other
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          governmental body, or as otherwise necessary to comply with applicable
          law, provided
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          that the Party making the disclosure pursuant to the order shall first
          have given written
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          notice to the other Party and made a reasonable effort to obtain a
          protective order. On the expiration or termination of the Agreement,
          the receiving Party shall
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          promptly return to the disclosing Party all copies, whether in
          written, electronic, or other
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          form or media, of the disclosing Party&rsquo;s Confidential
          Information, or, at the direction of Disclosing Party, destroy all
          such copies and certify in writing to the disclosing Party that such
          Confidential Information has been destroyed.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c7">
          Each Party&rsquo;s obligations of non-disclosure with regard to
          Confidential Information are effective as of the Effective Date{" "}
        </span>
        <span className="c7">
          and will expire five years from the date first disclosed to the
          receiving Party
        </span>
        <span className="c2">
          ; provided, however, with respect to any Confidential Information that
          constitutes a trade secret (as determined under applicable law), such
          obligations of non-disclosure will survive the termination or
          expiration of this Agreement for as long as such Confidential
          Information remains subject to trade secret protection under
          applicable law.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c6">7. Intellectual Property Ownership; Feedback</span>
      </p>
      <p className="c0">
        <span className="c5"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (a) Provider Materials. Customer acknowledges and agrees that, as
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          between Customer and Provider, Provider is and shall remain sole and
          exclusive
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          owner of all right, title, and interest in and to the Provider
          Materials and all
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          materials, information, processes and technology used by Provider or
          made
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          available to Customer in connection with the Services, and any and all
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          improvements, updates and modifications to any of the preceding
          (whether or
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          not made in conjunction with this Agreement), and all Intellectual
          Property Rights
        </span>
      </p>
      <p className="c1">
        <span className="c2">embodied in each of the preceding.</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (b) User Data. Provider acknowledges and agrees that, as between
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Provider and Customer, Customer and the Authorized Users, as
          applicable, own
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          all right, title, and interest, including all Intellectual Property
          Rights, in and to the
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          User Data. Customer and each Authorized User by acceptance of
          Provider&rsquo;s
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Terms of Service hereby grants to Provider a non-exclusive,
          royalty-free,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          worldwide license to reproduce, distribute, and otherwise use and
          display the
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          User Data (consistent with the Provider&rsquo;s Terms of Service) and
          perform all acts
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          with respect to the User Data as may be necessary for Provider to
          provide the
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Services to Customer, and a non-exclusive, perpetual, irrevocable,
          royalty-free,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          worldwide license to reproduce, distribute, modify, and otherwise use
          and
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          display, subject to the terms of this Agreement and applicable law,
          User Data
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          incorporated within the Aggregated Statistics. Customer and the
          Authorized
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Users shall have access to the User Data and shall be responsible for
          the User
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Data and the security of all passwords and other access protocols
          required in
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          order to access the Services. Anything herein or in the Terms of
          Service to the
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          contrary notwithstanding, Provider&rsquo;s sole obligation is to
          provide the Services on
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          the terms contained herein and in the Terms of Service and Provider is
          not
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          responsible for the accuracy, security or content of any User Data.
          Without
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          limiting the foregoing, Provider is not responsible for any act or
          inaction of
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Customer, any Authorized User or any third party that wrongfully
          accesses any of the
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Services. The uploading, posting or otherwise making available of User
          Data by
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Customer and Authorized Users through the Services is done at the sole
          risk and
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          responsibility of the Customer or Authorized User, as applicable.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (c) Feedback. If Customer or any of its employees or contractors sends
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          or transmits any communications or materials to Provider by mail,
          email,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          telephone, or otherwise, suggesting or recommending changes to the
          Provider
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Materials or any portion thereof, including without limitation, new
          features or
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          functionality relating thereto, or any comments, questions,
          suggestions, or the like
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          (collectively, &ldquo;Feedback&rdquo;), Provider is free to use such
          Feedback irrespective of
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          any other obligation or limitation between the Parties governing such
          Feedback.
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Customer hereby assigns to Provider on Customer&rsquo;s behalf, and on
          behalf of its
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          employees, contractors and/or agents, all right, title, and interest
          in, and Provider
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          is free to use, without any attribution or compensation to any party,
          any ideas,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          know-how, concepts, techniques, or other intellectual property rights
          contained in
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          the Feedback, for any purpose whatsoever, although Provider is not
          required to
        </span>
      </p>
      <p className="c1">
        <span className="c2">use any Feedback.</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c6">8. Limited Warranty and Warranty Disclaimer</span>
      </p>
      <p className="c0">
        <span className="c5"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (a) Provider warrants that the Services will conform in all material
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          respects to the specifications set forth in Exhibit B, if any, when
          accessed and used in
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          accordance with the Documentation. Provider does not make any
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          representations or guarantees regarding uptime or availability of the
          Services.
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          (b) EXCEPT FOR THE LIMITED WARRANTY SET FORTH IN SECTION
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          8(a), THE PROVIDER MATERIALS ARE MADE AVAILABLE &ldquo;AS IS&rdquo;
          AND
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          PROVIDER HEREBY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          IMPLIED, STATUTORY, OR OTHERWISE. PROVIDER SPECIFICALLY DISCLAIMS
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND ALL
        </span>
      </p>
      <p className="c0">
        <span className="c4"></span>
      </p>
      <p className="c1">
        <span className="c4">
          WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR TRADE
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          PRACTICE. EXCEPT FOR THE LIMITED WARRANTY SET FORTH IN SECTION
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          8(a), PROVIDER MAKES NO WARRANTY OF ANY KIND THAT THE PROVIDER
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          MATERIALS, OR ANY PRODUCTS OR RESULTS OF THE USE THEREOF, WILL
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          MEET CUSTOMER&rsquo;S OR ANY OTHER PERSON&rsquo;S REQUIREMENTS,
          OPERATE
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          OR WORK WITH ANY SOFTWARE, SYSTEM OR OTHER SERVICES, OR BE
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE, OR ERROR FREE.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (c) Customer acknowledges and understands that the disclaimers,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          exclusions and limitations of liability set forth in this Section 8
          form an essential
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          basis of the agreement between the Parties, that the Parties have
          relied upon
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          such disclaimers, exclusions and limitations of liability in
          negotiating the terms
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          and conditions in this Agreement, and that absent such disclaimers,
          exclusions
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          and limitations of liability, the terms and conditions of this
          Agreement would be
        </span>
      </p>
      <p className="c1">
        <span className="c2">substantially different.</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (d) Each Party represents, warrants and covenants to the other Party
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          that: (i) it is duly organized, validly existing and in good standing
          as a corporation
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          or other entity under the laws of the jurisdiction of its
          incorporation or other
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          organization; (ii) it has the full right, power, and authority to
          enter into and
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          perform its obligations under this Agreement; (iii) the execution of
          this Agreement
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          by its representative whose signature is set forth at the end of this
          Agreement
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          has been duly authorized by all necessary corporate or organizational
          action of
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          such Party; and (iv) when executed and delivered by both Parties, this
          Agreement
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          will constitute the legal, valid, and binding obligation of such
          Party, enforceable
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          against such Party in accordance with its terms.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c6">9. Indemnification</span>
      </p>
      <p className="c0">
        <span className="c5"></span>
      </p>
      <p className="c1">
        <span className="c2">
          Customer shall indemnify, hold harmless, and, at Provider&rsquo;s
          option, defend Provider from and against any Losses resulting from:
          (a) any third-party claim that the User Data, or any use of the User
          Data in accordance with this Agreement, infringes or misappropriation
          such third party&rsquo;s rights; and (b) any third-party claims based
          on Customer&rsquo;s or any Authorized User&rsquo;s (i) negligence or
          willful misconduct, (ii) use of the Services in a manner not
          authorized by this Agreement or the Terms of Service, (iii) use of the
          Services in combination with data, software,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          hardware, equipment or technology not provided by Provider or
          authorized by Provider
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          in writing, or (iv) modifications to the Services not made by
          Provider, provided that
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Customer may not settle any third-party claim against Provider unless
          Provider
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          consents to such settlement, and further provided that Provider will
          have the right, at its
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          option, to defend itself against any such third-party claim or to
          participate in the
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          defense thereof by counsel of its own choice. Provider shall
          indemnify, hold harmless,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          and, at Customer&#39;s option, defend Customer from and against any
          Losses incurred by
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Customer resulting directly and solely from (a) a final non-appealable
          determination by
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          a court of competent jurisdiction that the Services infringe on the
          Intellectual Property
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Rights of a third party, or (b) Provider&rsquo;s fraud or willful
          misconduct in the provision of the
        </span>
      </p>
      <p className="c1">
        <span className="c2">Services.</span>
      </p>
      <p className="c0">
        <span className="c6"></span>
      </p>
      <p className="c1">
        <span className="c6">10. Limitations of Liability</span>
      </p>
      <p className="c0">
        <span className="c5"></span>
      </p>
      <p className="c1">
        <span className="c2">
          IN NO EVENT WILL PROVIDER BE LIABLE UNDER OR IN CONNECTION WITH THIS
          AGREEMENT UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF
          CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND
          OTHERWISE, FOR ANY: (a) CONSEQUENTIAL, INCIDENTAL, INDIRECT,
          EXEMPLARY, SPECIAL, ENHANCED, OR PUNITIVE DAMAGES; (b) INCREASED
          COSTS, DIMINUTION IN VALUE OR LOST BUSINESS, PRODUCTION,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          REVENUES, OR PROFITS; (c) LOSS OF GOODWILL OR REPUTATION; (d) USE,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          INABILITY TO USE, LOSS, INTERRUPTION, DELAY OR RECOVERY OF ANY DATA,
          OR
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          BREACH OF DATA OR SYSTEM SECURITY; OR (e) COST OF REPLACEMENT GOODS OR
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          SERVICES, IN EACH CASE REGARDLESS OF WHETHER PROVIDER WAS ADVISED OF
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          WERE OTHERWISE FORESEEABLE. IN NO EVENT WILL PROVIDER&rsquo;S
          AGGREGATE
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT UNDER ANY LEGAL
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE, EXCEED THE TOTAL AMOUNTS
          OF FEES PAID TO PROVIDER UNDER THIS AGREEMENT IN THE 12-MONTH PERIOD
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          PRECEDING THE EVENT GIVING RISE TO THE LIABILITY. IN NO EVENT SHALL
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          PROVIDER&rsquo;S LIABILITY FOR INDEMNIFICATION EXCEED THE AMOUNT OF
          FEES PAID
        </span>
      </p>
      <p className="c1">
        <span className="c2">BY CUSTOMER TO PROVIDER HEREUNDER.</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c6">11. Term and Termination</span>
      </p>
      <p className="c0">
        <span className="c5"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (a) Term. The initial term of this Agreement begins on the Effective
          Date
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          and, unless terminated earlier pursuant to this Agreement&rsquo;s
          express provisions,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          will continue in effect until June 30th, 2026 (the &ldquo;Initial
          Term&rdquo;). This Agreement
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          will automatically renew for additional successive one (1) year terms
          at a
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          prorated annual rate unless earlier terminated pursuant to this
          Agreement&rsquo;s
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          express provisions or either Party gives the other Party written
          notice of
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          non-renewal at least sixty (60) days prior to the expiration of the
          then-current
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          term (each a &ldquo;Renewal Term&rdquo; and together with the Initial
          Term, the &ldquo;Term&rdquo;).
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          (b) Termination. In addition to any other express termination right
          set
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          forth in this Agreement: (i) Provider may terminate this Agreement,
          effective on written
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          notice to Customer, if Customer: (A) fails to pay any amount when due
        </span>
      </p>
      <p className="c1">
        <span className="c7">
          hereunder, and such failure continues more than{" "}
        </span>
        <span className="c7">forty five (45) days </span>
        <span className="c2">after</span>
      </p>
      <p className="c1">
        <span className="c2">
          Provider&rsquo;s delivery of written notice thereof; or (B) breaches
          any of its
        </span>
      </p>
      <p className="c1">
        <span className="c2">obligations under Section 2(c) or Section 6;</span>
      </p>
      <p className="c1">
        <span className="c2">
          (ii) either Party may terminate this Agreement, effective on
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          written notice to the other Party, if the other Party materially
          breaches this
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Agreement, and such breach: (A) is incapable of cure; or (B) being
          capable
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          of cure, remains uncured thirty (30) days after the non-breaching
          Party
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          provides the breaching Party with written notice of such breach; or
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          (iii) either Party may terminate this Agreement, effective
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          immediately upon written notice to the other Party, if the other
          Party: (A)
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          becomes insolvent or is generally unable to pay, or fails to pay, its
          debts as
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          they become due; (B) files or has filed against it, a petition for
          voluntary or
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          involuntary bankruptcy or otherwise becomes subject, voluntarily or
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          involuntarily, to any proceeding under any domestic or foreign
          bankruptcy
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          or insolvency law; (C) makes or seeks to make a general assignment for
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          the benefit of its creditors; or (D) applies for or has appointed a
          receiver,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          trustee, custodian, or similar agent appointed by order of any court
          of
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          competent jurisdiction to take charge of or sell any material portion
          of its
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          property or business. (c) Effect of Expiration or Termination. Upon
          expiration or earlier
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          termination of this Agreement, Customer will immediately discontinue
          use of the
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Provider Materials and, without limiting Customer&rsquo;s obligations
          under Section 6,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Customer shall delete, destroy, or return all copies of the Provider
          Materials and
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          have an officer of Customer certify in writing to the Provider that
          the Provider
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Materials has been deleted or destroyed. No expiration or termination
          will affect
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Customer&rsquo;s obligation to pay all Fees that may have become due
          before such
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          expiration or termination, or entitle Customer to any refund.
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          (d) Survival. This Section 11(d) and Sections 1, 5, 6, 7, 8(b), 9, 10,
          and
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          12 survive any termination or expiration of this Agreement. No other
          provisions of
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          this Agreement survive the expiration or earlier termination of this
          Agreement.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c6">12. Miscellaneous</span>
      </p>
      <p className="c1">
        <span className="c2">
          (a) Entire Agreement. This Agreement, together with any other
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          documents incorporated herein by reference and all related Exhibits,
          constitutes
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          the sole and entire agreement of the Parties with respect to the
          subject matter of
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          this Agreement and supersedes all prior and contemporaneous
          understandings,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          agreements, and representations and warranties, both written and oral,
          with
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          respect to such subject matter. In the event of any inconsistency
          between the
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          statements made in the body of this Agreement, the related Exhibits,
          and any
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          other documents incorporated herein by reference (including without
          limitation
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Provider&rsquo;s Terms of Service), the following order of precedence
          governs: (i) first,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          this Agreement, excluding its Exhibits; (ii) second, the Exhibits to
          this Agreement
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          as of the Effective Date; and (iii) third, any other documents
          incorporated herein
        </span>
      </p>
      <p className="c1">
        <span className="c2">by reference.</span>
      </p>
      <p className="c1">
        <span className="c2">
          (b) Notices. All notices, requests, consents, claims, demands,
          waivers,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          and other communications hereunder (each, a &ldquo;Notice&rdquo;) must
          be in writing and
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          addressed to the Parties at the addresses set forth on the first page
          of this
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          Agreement (or to such other address that may be designated by the
          Party giving
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Notice from time to time in accordance with this Section). All Notices
          must be
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          delivered by personal delivery, nationally recognized overnight
          courier (with all
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          fees pre-paid), facsimile or email (with confirmation of transmission)
          or certified
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          or registered mail (in each case, return receipt requested, postage
          pre-paid).
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Except as otherwise provided in this Agreement, a Notice is effective
          only: (i)
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          upon receipt by the receiving Party; and (ii) if the Party giving the
          Notice has
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          complied with the requirements of this Section.
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          (c) Force Majeure. In no event shall Provider be liable to Customer,
          or be
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          deemed to have breached this Agreement, for any failure or delay in
          performing
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          its obligations under this Agreement, if and to the extent such
          failure or delay is
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          caused by any circumstances beyond Provider&rsquo;s reasonable
          control, including but
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          not limited to acts of God, war (whether or not actually declared),
          armed conflict
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          or the serious threat of the same, hostility, blockade, military
          embargo, sabotage,
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          insurrection, rebellion, act of a public enemy, riot or other act of
          civil disobedience,
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          governmental act, judicial action, explosion, act of terrorism or
          threat thereof
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          (including cyberterrorism), natural disaster (including without
          limitation asteroid
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          strikes or volcanic activity), violent storm (including without
          limitation hurricanes,
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          tornados or blizzards), atmospheric disturbance (including without
          limitation
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          geomagnetic storm, solar flare or sun outage with respect to
          electricity grids,
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          transformers and satellite transmissions), destruction by lightning,
          fire,
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          earthquake, tsunami, flood, plague, epidemic, pan-epidemic,
          quarantine, civil
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          commotion, strike or lockout or labor dispute (excluding for the
          avoidance of
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          doubt strikes of Provider&rsquo;s staff), satellite malfunction,
          prolonged internet outage,
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          communications line failure or power failure or other industrial
          disturbances, or
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          passage of law or any action taken by a governmental or public
          authority,
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          including imposing an embargo.(d) Amendment and Modification; Waiver.
          No amendment to or
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          modification of this Agreement is effective unless it is in writing
          and signed by an
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          authorized representative of each Party. No waiver by any Party of any
          of the
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          provisions hereof will be effective unless explicitly set forth in
          writing and signed
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          by the Party so waiving. Except as otherwise set forth in this
          Agreement, (i) no
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          failure to exercise, or delay in exercising, any rights, remedy,
          power, or privilege
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          arising from this Agreement will operate or be construed as a waiver
          thereof and
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          (ii) no single or partial exercise of any right, remedy, power, or
          privilege hereunder
        </span>
      </p>
      <p className="c1">
        <span className="c4">
          will preclude any other or further exercise thereof or the exercise of
          any other
        </span>
      </p>
      <p className="c1">
        <span className="c4">right, remedy, power, or privilege.</span>
      </p>
      <p className="c0">
        <span className="c4"></span>
      </p>
      <p className="c0">
        <span className="c4"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (e) Severability. If any provision of this Agreement is invalid,
          illegal, or
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          unenforceable in any jurisdiction, such invalidity, illegality, or
          unenforceability will
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          not affect any other term or provision of this Agreement or invalidate
          or render
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          unenforceable such term or provision in any other jurisdiction. Upon
          such
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          determination that any term or other provision is invalid, illegal, or
          unenforceable,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          the Parties shall negotiate in good faith to modify this Agreement so
          as to effect
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          their original intent as closely as possible in a mutually acceptable
          manner in
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          order that the transactions contemplated hereby be consummated as
          originally
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          contemplated to the greatest extent possible.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c7">
          (f) Governing Law; Submission to Jurisdiction.{" "}
        </span>
        <span className="c2">This Agreement is</span>
      </p>
      <p className="c1">
        <span className="c2">
          governed by and construed in accordance with the internal laws of the
          State of
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Washington without giving effect to any choice or conflict of law
          provision or rule
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          that would require or permit the application of the laws of any
          jurisdiction other
        </span>
      </p>
      <p className="c1">
        <span className="c2">than those of the State of Washington.</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (g) Assignment. Customer may not assign any of its rights or delegate
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          any of its obligations hereunder, in each case whether voluntarily,
          involuntarily,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          by operation of law or otherwise, without the prior written consent of
          Provider.
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Any purported assignment or delegation in violation of this Section
          will be null
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          and void. No assignment or delegation will relieve the assigning or
          delegating
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Party of any of its obligations hereunder. This Agreement is binding
          upon and
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          inures to the benefit of the Parties and their respective permitted
          successors and
        </span>
      </p>
      <p className="c1">
        <span className="c2">assigns.</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (h) Export Regulation. Customer shall comply with all applicable
          federal
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          laws, regulations, and rules, and complete all required undertakings
          (including
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          obtaining any necessary export license or other governmental
          approval), that
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          prohibit or restrict the export or re-export of the Services or any
          User Data
        </span>
      </p>
      <p className="c1">
        <span className="c2">outside the US.</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          (i) Equitable Relief. Each Party acknowledges and agrees that a
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          breach or threatened breach by such Party of any of its obligations
          under Section
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          6 or, in the case of Customer, Section 2(c), would cause the other
          Party
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          irreparable harm for which monetary damages would not be an adequate
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          remedy and agrees that, in the event of such breach or threatened
          breach, the
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          other Party will be entitled to equitable relief, including a
          restraining order, an
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          injunction, specific performance and any other relief that may be
          available from
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          any court, without any requirement to post a bond or other security,
          or to prove
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          actual damages or that monetary damages are not an adequate remedy.
          Such
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          remedies are not exclusive and are in addition to all other remedies
          that may be
        </span>
      </p>
      <p className="c1">
        <span className="c2">available at law, in equity or otherwise.</span>
      </p>
      <p className="c1">
        <span className="c2">
          (j) Counterparts. This Agreement may be executed in counterparts,
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          each of which is deemed an original, but all of which together are
          deemed to be
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          one and the same agreement. A signed copy of this Agreement delivered
          by
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          facsimile, e-mail or other means of electronic transmission,
          including, but not limited to, creating an account, signing up,
          logging in, or the use of the Impart Inc.Impact Partners Network site,
          shall be deemed to have the same legal effect as delivery of an
          original signed copy of this
        </span>
      </p>
      <p className="c1">
        <span className="c2">Agreement.</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          Contact us for any questions or concerns regarding this Agreement at:
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">By mail: </span>
      </p>
      <p className="c1">
        <span className="c2">Attn: SaaS Agreement Inquiry:</span>
      </p>
      <p className="c1">
        <span className="c2">Impart Inc.</span>
      </p>
      <p className="c1">
        <span className="c2">600 1st Avenue, Suite 103, </span>
      </p>
      <p className="c1">
        <span className="c2">Seattle, WA 98104</span>
      </p>
      <p className="c1">
        <span className="c7">By email: </span>
        <span className="c15 c7">
          <a className="c12" href="mailto:support@impactpartnersnetwork.com">
            support@impactpartnersnetwork.com
          </a>
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c10 c7"></span>
      </p>
    </div>
  );
}

export default LegalPolicies;
