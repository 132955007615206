import "./MyProfile.scss";

import React from "react";
import { Col, Form, Row } from "react-bootstrap";

import UserBadge from "../../components/UserBadge";
import useAuth from "../../hooks/useAuth";

function MyProfile() {
  const { auth } = useAuth();
  return (
    <>
      <div className="my-profile pt-5 px-5">
        <Row className="justify-content-center">
          <Col md={7}>
            <div className="d-flex justify-content-center">
              <UserBadge user={auth?.user} editMode />
            </div>
            <Row className="notifications d-flex justify-content-center mb-5">
              <Col md={10}>
                <Form>
                  <h4 className="my-5 fw-bold text-black">
                    Notifications will be sent via email
                  </h4>
                  <Form.Check
                    type="checkbox"
                    className="d-flex justify-content-between">
                    <Form.Check.Label className="text-black">
                      <strong>Email notifications</strong>
                      <small className="d-block fw-normal">
                        {auth?.user?.email}
                      </small>
                    </Form.Check.Label>
                  </Form.Check>

                  <strong className="py-5 d-block">
                    Contact support to change your email. Thank you.
                  </strong>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default MyProfile;
