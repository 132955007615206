import React from "react";
import { Form } from "react-bootstrap";

function TextArea({
  id,
  label,
  name,
  placeholder,
  onChange,
  value,

  style,
  ...props
}) {
  return (
    <Form.Group controlId={id} className="mb-3">
      <Form.Label>{label}</Form.Label>
      <Form.Control
        as="textarea"
        placeholder={placeholder ? placeholder : label}
        name={name}
        onChange={onChange}
        value={value || ""}
        style={style || { height: "200px" }}
        {...props}
      />
    </Form.Group>
  );
}

export default TextArea;
