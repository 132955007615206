import "./App.scss";
import "react-toastify/dist/ReactToastify.css";

import { Route, Routes } from "react-router";
import { Container } from "react-bootstrap";
import CreateCommunityRequest from "./pages/community-requests/CreateCommunityRequest";
import FindOrganizations from "./pages/organizations/FindOrganizations";
import Dashboard from "./pages/Dashboard";
import Support from "./pages/Support";
import Pricing from "./pages/Pricing";
import Org from "./pages/organizations/Org";
import { ToastContainer } from "react-toastify";
import ChatPanel from "./components/ChatPanel";
import Collaborations from "./pages/community-requests/Collaborations";
import CommunityEvents from "./pages/events/CommunityEvents";
import FollowedEvents from "./pages/events/FollowedEvents";
import MyNetwork from "./pages/organizations/MyNetwork";
import CommunityRequests from "./pages/community-requests/CommunityRequests";
import CommunityRequestsActivity from "./pages/community-requests/CommunityRequestsActivity";
import CommunityRequestAssets from "./pages/community-requests/CommunityRequestAssets";
import CommunityRequestDrafts from "./pages/community-requests/CommunityRequestDrafts";
import MainLayout from "./layouts/MainLayout";
import MyProfile from "./pages/settings/MyProfile";
import EditOrgProfile from "./pages/organizations/EditOrgProfile";
import Events from "./pages/settings/Events";
import SettingsLayout from "./layouts/SettingsLayout";
import AuthLayout from "./layouts/AuthLayout";
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import RequireAuth from "./components/auth/RequireAuth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PrivacyPolicy from "./pages/LegalPolicies";
import TermsAndAgreements from "./pages/TermsAndAgreements";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ViewCommunityRequest from "./pages/community-requests/ViewCommunityRequest";
import EditCommunityRequest from "./pages/community-requests/EditCommunityRequest";
import AcceptCommunityRequestAssetsQrView from "./pages/community-requests/AcceptCommunityRequestAssetsQrView";
import { CommunityRequestStatus } from "./constants";
import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";
import MobileLayout from "./layouts/MobileLayout";
import Bids from "./pages/Bids";
import Messages from "./pages/Messages";
import SearchOpportunities from "./pages/opportunities/SearchOpportunities";
import Signup from "./pages/auth/Signup";
import RequireNonProfit from "./components/auth/RequireGovType";
import PublishedCommunityRequests from "./pages/community-requests/PublishedCommunityRequests";
import LegalPolicies from "./pages/LegalPolicies";

const queryClient = new QueryClient();
const pubnub = new PubNub({
  publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
  subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBER_KEY,
  userId: "default",
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <PubNubProvider client={pubnub}>
        <ToastContainer className="notification" />
        <Container
          className="app d-flex flex-column min-vh-100 g-0 overflow-hidden"
          fluid>
          <Routes>
            <Route element={<MobileLayout />}>
              <Route element={<RequireAuth />}>
                <Route
                  path="/community-requests/:slug/received-assets"
                  element={<AcceptCommunityRequestAssetsQrView />}
                />
              </Route>
            </Route>
            <Route path="/auth" element={<AuthLayout />}>
              <Route path="login" element={<Login />}></Route>
              <Route path="hubspotSignup" element={<hubspotSignup />}></Route>
              <Route path="signup" element={<Signup />}></Route>
              <Route
                path="forgot-password"
                element={<ForgotPassword />}></Route>
              <Route path="reset-password" element={<ResetPassword />}></Route>
            </Route>

            <Route element={<MainLayout />}>
              <Route element={<RequireAuth />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route
                  path="/opportunities/:noticeId?"
                  element={<SearchOpportunities />}
                />
                <Route path="/bids" element={<Bids />} />
                <Route
                  path="/community-requests/activity"
                  element={<CommunityRequestsActivity />}
                />
                <Route
                  path="/community-requests/assets"
                  element={<CommunityRequestAssets />}
                />
                <Route
                  path="/community-requests/drafts"
                  element={<CommunityRequestDrafts />}
                />
                <Route
                  path="/community-requests"
                  element={<PublishedCommunityRequests />}
                />
                <Route path="/collaborations" element={<Collaborations />} />
                <Route
                  path="/community-requests/pending"
                  element={
                    <CommunityRequests
                      status={
                        CommunityRequestStatus.COMMUNITY_REQUEST_STATUS_IN_REVIEW
                      }
                    />
                  }
                />
                <Route
                  path="/community-requests/completed"
                  element={
                    <CommunityRequests
                      status={
                        CommunityRequestStatus.COMMUNITY_REQUEST_STATUS_COMPLETED
                      }
                    />
                  }
                />

                <Route
                  path="/community-requests/published"
                  element={
                    <CommunityRequests
                      status={
                        CommunityRequestStatus.COMMUNITY_REQUEST_STATUS_PUBLISHED
                      }
                    />
                  }
                />
                <Route
                  path="/community-requests/:slug"
                  element={<ViewCommunityRequest />}
                />

                <Route element={<RequireNonProfit />}>
                  <Route
                    path="/community-requests/:slug/edit"
                    element={<EditCommunityRequest />}
                  />
                  <Route
                    path="/community-requests/new"
                    element={<CreateCommunityRequest />}
                  />
                </Route>
                <Route
                  path="/find-organization"
                  element={<FindOrganizations />}
                />
                <Route path="/my-network" element={<MyNetwork />} />
                <Route path="/messages" element={<Messages />} />

                <Route path="/organization/:slug" element={<Org />} />
                <Route path="/events/community" element={<CommunityEvents />} />
                <Route path="/events/followed" element={<FollowedEvents />} />

                <Route path="/chat" element={<ChatPanel />} />
                <Route path="/settings" element={<SettingsLayout />}>
                  <Route path="my-profile" element={<MyProfile />} />
                  <Route path="org-profile" element={<EditOrgProfile />} />
                  <Route path="events/:id?" element={<Events />} />
                </Route>
                <Route path="/support" element={<Support />} />
              </Route>
              <Route path="/legal-policies" element={<LegalPolicies />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/terms-and-agreements"
                element={<TermsAndAgreements />}
              />
            </Route>
          </Routes>
        </Container>
      </PubNubProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
