import "./Box.scss";
import React from "react";
import { Image } from "react-bootstrap";
import Loading from "../components/Loading";

function Box({ title, children, icon, className, loading, ...props }) {
  return (
    <div className={`box ${className}`} {...props}>
      {title && (
        <div className="d-flex justify-content-between header">
          {title && <div className="title">{title}</div>}
          {icon && <Image src={icon} />}
        </div>
      )}
      {loading && <Loading size="sm" />}
      {children && <div className="content">{children}</div>}
    </div>
  );
}

export default Box;
