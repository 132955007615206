import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "../../components/Button";
import TextInput from "../../components/forms/TextInput";
import TextArea from "../../components/forms/TextArea";

import useForm from "../../hooks/useForm";
import Panel from "../../components/Panel";
import ImageUploader from "../../components/ImageUploader";
import logoUploadImage from "../../assets/images/logo_upload.png";
import coverPhotoUploadImage from "../../assets/images/cover_photo_upload.png";
import { useNavigate } from "react-router-dom";

function OrgProfileForm({ profileData, setProfileData, fromPreview }) {
  const navigate = useNavigate();
  const submitForm = () => {};
  const { formData, handleChange, handleSubmit, validated } = useForm(
    { ...profileData },
    () => {
      submitForm();
    }
  );

  const [images, setImages] = useState([
    formData.logoPreview,
    formData.coverPhotoPreview,
  ]);

  useEffect(() => {
    if (fromPreview) return;

    if (formData.logo) {
      setImages((images) =>
        images.map((img, idx) => (idx === 0 ? formData.logo : img))
      );
    }

    if (formData.cover_photo) {
      setImages((images) =>
        images.map((img, idx) => (idx === 1 ? formData.cover_photo : img))
      );
    }
  }, [formData.cover_photo, formData.logo, fromPreview]);

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="justify-content-center">
          <Col md={7}>
            <Panel>
              <div>
                <Row>
                  <Col md={10}>
                    <h2 className="pt-3">Organization Details</h2>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <TextInput
                      id="organization"
                      label="Organization"
                      name="name"
                      onChange={handleChange}
                      value={formData.name}
                      required
                      readOnly
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      label="Org ID"
                      id="orgId"
                      name="id"
                      onChange={handleChange}
                      value={formData?.slug}
                      readOnly
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <TextInput
                      id="location"
                      label="Location"
                      name="location"
                      onChange={handleChange}
                      value={formData.location}
                      readOnly
                      required
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      id="coreCause"
                      label="Core Cause"
                      name="core_cause"
                      onChange={handleChange}
                      value={formData.core_cause}
                      readOnly
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <TextInput
                      id="email"
                      label="Email address"
                      name="email"
                      type="email"
                      onChange={handleChange}
                      value={formData.email}
                      readOnly
                      required
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      id="phone"
                      label="Phone Number"
                      type="tel"
                      name="phone"
                      onChange={handleChange}
                      value={formData.phone}
                      maxLength="10"
                      pattern="[0-9]{10}"
                      readOnly
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <TextInput
                      id="website"
                      label="Website"
                      name="website"
                      onChange={handleChange}
                      value={formData.website}
                      required
                    />
                  </Col>
                  <Col md={6} className="d-flex align-items-center">
                    <strong className="pt-4">
                      *Verification will appear when published
                    </strong>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <ImageUploader
                      title="Logo"
                      imageIndex={0}
                      prefilledImage={images[0]}
                      setImages={setImages}
                      name="logo-upload"
                      previewOnly={fromPreview}
                      backgroundImage={
                        formData.logoPreview
                          ? formData.logoPreview
                          : logoUploadImage
                      }
                      style={{ width: 143, height: 120 }}
                    />
                  </Col>
                  <Col md={6}>
                    <ImageUploader
                      title="Cover Photo"
                      imageIndex={1}
                      prefilledImage={images[1]}
                      setImages={setImages}
                      name="cover-photo-upload"
                      previewOnly={fromPreview}
                      backgroundImage={
                        formData.coverPhotoPreview
                          ? formData.coverPhotoPreview
                          : coverPhotoUploadImage
                      }
                      style={{ width: 312, height: 120 }}
                    />
                  </Col>
                </Row>
              </div>
            </Panel>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={7}>
            <Panel>
              <div>
                <Row>
                  <Col md={10}>
                    <h2 className="pt-3">Social Media Links</h2>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <TextInput
                      id="facebook"
                      label="Facebook"
                      name="facebook_link"
                      onChange={handleChange}
                      value={formData.facebook_link}
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      id="twitter"
                      label="Twitter"
                      name="twitter_link"
                      onChange={handleChange}
                      value={formData.twitter_link}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <TextInput
                      id="linkedin"
                      label="Linkedin"
                      name="linkedin_link"
                      onChange={handleChange}
                      value={formData.linkedin_link}
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      id="instagram"
                      label="Instagram"
                      name="instagram_link"
                      onChange={handleChange}
                      value={formData.instagram_link}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <TextInput
                      id="tiktok"
                      label="Tiktok"
                      name="tiktok_link"
                      onChange={handleChange}
                      value={formData.tiktok_link}
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      id="otherSocial"
                      label="Other"
                      name="other_link"
                      onChange={handleChange}
                      value={formData.other_link}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mt-3">
                    <TextInput
                      id="orgTagline"
                      label="Organization Tagline"
                      name="tagline"
                      onChange={handleChange}
                      value={formData.tagline}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mt-3">
                    <TextArea
                      id="orgDescription"
                      label="Organization Description"
                      name="description"
                      onChange={handleChange}
                      value={formData.description}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="d-flex flex-row-reverse my-3">
                    <Button
                      type="button"
                      size="lg"
                      variant="primary"
                      className="ms-2"
                      onClick={(e) => {
                        formData.logoPreview = images[0];
                        formData.coverPhotoPreview = images[1];
                        setProfileData(formData);
                        navigate("/settings/org-profile", {
                          state: {
                            preview: true,
                          },
                        });
                        window.scrollTo(0, 0);
                      }}>
                      Preview
                    </Button>
                  </Col>
                </Row>
              </div>
            </Panel>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default OrgProfileForm;
