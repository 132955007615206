import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useNotification from "../../hooks/useNotification";
import { OrgTypes } from "../../constants";

const RequireNonProfit = () => {
  const { notify } = useNotification();

  const { auth } = useAuth();
  const location = useLocation();

  const isGov = auth?.user?.organization?.type === OrgTypes.NP;

  if (!isGov) {
    notify(
      "error",
      "Only government organizations can create CommunityRequests."
    );
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  return auth?.accessToken ? <Outlet /> : "Loading...";
};

export default RequireNonProfit;
