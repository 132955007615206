import { useMemo, useState } from "react";
import OrgHeader from "../../components/navigation/OrgHeader";
import DataGrid from "../../components/DataGrid";
import deleteIcon from "../../assets/images/icons/delete.png";
import { Button, Image } from "react-bootstrap";

function FollowedEvents() {
  const [{ pageIndex }, setPagination] = useState({
    pageIndex: 0,
  });
  const [data] = useState([
    {
      eventTitle: "Event Title",
      organization: "Org 1",
      industry: "Industry",
      description: "OKR Planning Webinar",
      date: "August 4th, 2023",
      time: "7pm PST",
      eventLink: "www.myevent.com",
      followed: true,
    },
    {
      eventTitle: "Event Title",
      organization: "Org 1",
      industry: "Industry",
      description: "OKR Planning Webinar",
      date: "August 4th, 2023",
      time: "7pm PST",
      eventLink: "www.myevent.com",
    },
    {
      eventTitle: "Event Title",
      organization: "Org 1",
      industry: "Industry",
      description: "OKR Planning Webinar",
      date: "August 4th, 2023",
      time: "7pm PST",
      eventLink: "www.myevent.com",
    },
    {
      eventTitle: "Event Title",
      organization: "Org 1",
      industry: "Industry",
      description: "OKR Planning Webinar",
      date: "August 4th, 2023",
      time: "7pm PST",
      eventLink: "www.myevent.com",
    },
  ]);
  const columns = useMemo(
    () => [
      { header: "Event Title", accessorKey: "eventTitle" },
      { header: "Organization", accessorKey: "organization" },
      { header: "Industry", accessorKey: "industry" },
      { header: "Description", accessorKey: "description" },
      { header: "Date", accessorKey: "date" },
      { header: "Time", accessorKey: "time" },
      { header: "Event Link", accessorKey: "eventLink" },
      {
        accessorKey: "action",
        cell: ({ cell }) => {
          return (
            <div className="text-end">
              <Button variant="light">
                <Image src={deleteIcon} />
              </Button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <OrgHeader />
      <h3 className="mb-0">Events followed by my organization</h3>
      <DataGrid
        columns={columns}
        // onRowClick={(e, row) => navigate(`/event/${row.eventTitle}`)}
        data={data}
        pageIndex={pageIndex}
        setPagination={setPagination}
        pageCount={data?.result?.last_page}
        comingSoon
      />
    </>
  );
}

export default FollowedEvents;
