import "./AuthLayout.scss";

import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import logo from "../assets/images/ip_logo_with_text.png";
import ipLogo from "../assets/images/ip_logo.png";
import ilLogo from "../assets/images/il_logo.png";

function AuthLayout() {
  return (
    <div className="auth min-vh-100">
      <Container className="bg-white min-vh-100 d-flex">
        <Row className="justify-content-center flex-grow-1">
          <Col md={10} className="d-flex flex-column">
            <Image src={logo} className="mx-auto d-block" />
            <h1 className="text-center fs-4 my-3">Impact Partners Network</h1>
            <Outlet />
            <p className="copyright text-center mt-auto mb-5">
              Copyright 2024 <Image src={ipLogo} height={10} /> Impart Inc. A
              subsidiary of <Image src={ilLogo} height={10} /> Intention Labs
              LLC. All rights reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AuthLayout;
