import React from "react";

const StripeBuyButton = ({
  buyButtonId,
  publishableKey,
  clientReferenceId,
  customerEmail,
}) => {
  return (
    <stripe-buy-button
      buy-button-id={buyButtonId}
      publishable-key={publishableKey}
      client-reference-id={clientReferenceId}
      customer-email={customerEmail}></stripe-buy-button>
  );
};

export default StripeBuyButton;
