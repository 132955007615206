import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ErrorFallback from "../components/ErrorFallback";
import Footer from "../components/Footer";
import SideBar from "../components/navigation/SideBar";
import TopBar from "../components/navigation/TopBar";
import * as Sentry from "@sentry/react";

function MainLayout() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <div className="layout flex-grow-1">
        <SideBar />
        <div className="g-0 mx-0 px-0">
          <TopBar />
          <main>
            <Sentry.ErrorBoundary
              fallback={ErrorFallback}
              onReset={() => {
                navigate(0);
              }}
              resetKeys={[location.key]}>
              <Outlet />
            </Sentry.ErrorBoundary>
          </main>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default MainLayout;
