import React from "react";

import Button from "../Button";
import useLogout from "../../hooks/useLogout";

const LogoutButton = (props) => {
  const logout = useLogout();
  return (
    <Button variant="link" onClick={logout} {...props}>
      Log Out
    </Button>
  );
};

export default LogoutButton;
