import React from "react";
import { Link } from "react-router-dom";
import {
  CommunityRequestAssetStatus,
  CollaborationStatus,
} from "../../constants";
import useAuth from "../../hooks/useAuth";
import { isCollaborator, isCreator } from "../../utils";
import Button from "../Button";

function CommunityRequestActionButton({
  communityRequestData,
  setShowReleaseAssetsModal,
  setShowAcceptAssetsModal,
  setShowCompleteCommunityRequestModal,
  setShowRejectAssetsModal,
}) {
  const { auth } = useAuth();

  const collaborator = isCollaborator(communityRequestData, auth?.user);
  const creator = isCreator(communityRequestData, auth?.user);
  const communityRequestCompleted =
    communityRequestData?.collaboration?.status ===
    CollaborationStatus.COMPLETED;
  const packageStatus =
    communityRequestData?.collaboration?.asset_package_status;
  const assetsReady =
    packageStatus ===
    CommunityRequestAssetStatus.COMMUNITY_REQUEST_ASSETS_ACTIVITY_INITIATED;
  const assetsReleased =
    packageStatus ===
    CommunityRequestAssetStatus.COMMUNITY_REQUEST_ASSETS_ACTIVITY_RELEASED;
  const assetsAccepted =
    packageStatus ===
    CommunityRequestAssetStatus.COMMUNITY_REQUEST_ASSETS_ACTIVITY_ACCEPTED;

  //   <Button variant="success" disabled>
  //   Collaboration Completed
  // </Button>
  return (
    <div className="community-request-action py-3 mt-3">
      {communityRequestCompleted ? (
        ""
      ) : (
        <>
          {assetsReady && (
            <Button
              variant="primary"
              className="reverse"
              disabled={collaborator}
              onClick={() => setShowReleaseAssetsModal(true)}>
              {creator ? "Release Assets" : "Waiting For Assets"}
            </Button>
          )}

          {assetsReleased && (
            <>
              <Button
                variant="success"
                disabled={creator}
                onClick={() => setShowAcceptAssetsModal(true)}>
                {creator ? "Assets Released" : "Accept Assets"}
              </Button>
              {collaborator && (
                <Link
                  to="#"
                  variant="danger"
                  className="ms-3 fs-5 text-black"
                  onClick={() => setShowRejectAssetsModal(true)}>
                  Reject Assets
                </Link>
              )}
            </>
          )}
          {assetsAccepted && (
            <Button
              variant="primary"
              disabled={collaborator}
              className="reverse"
              onClick={() => setShowCompleteCommunityRequestModal(true)}>
              {creator ? "Complete Collaboration" : "Assets Accepted"}
            </Button>
          )}
        </>
      )}
    </div>
  );
}

export default CommunityRequestActionButton;
