import "./CommunityRequestsList.scss";

import React, { useMemo, useState } from "react";
import DataGrid from "../DataGrid";
import OrgBadge from "../OrgBadge";
import { useFetchCommunityRequestsByFilters } from "../../api/communityRequests";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

function CommunityRequestsList({ title, orgId, status, isActive }) {
  const [{ pageIndex }, setPagination] = useState({
    pageIndex: 0,
  });
  const { auth } = useAuth();
  const { isLoading, data } = useFetchCommunityRequestsByFilters({
    orgId: orgId ?? auth?.user?.organization?.slug,
    status,
    isActive,
    pageIndex,
  });
  const navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        header: "Organization",
        accessorKey: "org",
        cell: ({ cell }) => (
          <>
            <OrgBadge org={cell.row.original.organization} mini />
          </>
        ),
      },
      { header: "Assets", accessorFn: (row) => row.asset_packages[0]?.item },
      { header: "Address", accessorKey: "pickup_complete_address", width: 150 },
      { header: "Date & Time", accessorKey: "created_at" },
    ],
    []
  );
  return (
    <div className="community-requests-list">
      <DataGrid
        title={title}
        columns={columns}
        isLoading={isLoading}
        data={data?.result?.data ?? []}
        onRowClick={(e, row) => navigate(`/community-requests/${row.slug}`)}
        pageIndex={pageIndex}
        setPagination={setPagination}
        pageCount={data?.result?.last_page}
      />
    </div>
  );
}

export default CommunityRequestsList;
