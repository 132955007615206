import React, { useMemo, useState } from "react";
import DataGrid from "../DataGrid";
import { useFetchCommunityRequestDrafts } from "../../api/communityRequests";
import { useLocation, useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../ErrorFallback";

function CommunityRequestDraftsTable({ collapsible }) {
  const [{ pageIndex }, setPagination] = useState({
    pageIndex: 0,
  });
  const { isLoading, data } = useFetchCommunityRequestDrafts(pageIndex);
  const navigate = useNavigate();
  const location = useLocation();

  const drafts = data?.result.data;
  const columns = useMemo(
    () => [
      { header: "Request ID", accessorKey: "slug" },
      { header: "Request Title", accessorKey: "title" },
      {
        header: "Asset Description",
        accessorKey: "assetDescription",
        cell: ({ cell }) => {
          const assets = cell.row.original.asset_packages;
          if (assets.length === 0) return <></>;
          const asset = assets[0];
          return (
            <>
              {asset?.category?.name}{" "}
              <strong className="d-block">{asset?.item}</strong>
            </>
          );
        },
      },

      {
        header: "Originating Location",
        accessorKey: "pickup_complete_address",
      },
      { header: "Date & Time", accessorKey: "created_at" },
      {
        header: "Drafted By",
        accessorKey: "draftedBy",
        cell: ({ cell }) => (
          <>
            {cell.row.original.drafted_by?.first_name}{" "}
            {cell.row.original.drafted_by?.last_name}
          </>
        ),
      },
    ],
    []
  );

  return (
    <div>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        resetKeys={[location.key]}>
        <DataGrid
          title="Community Request Drafts"
          columns={columns}
          isLoading={isLoading}
          data={drafts ?? []}
          onRowClick={(e, row) =>
            navigate(`/community-requests/${row.slug}/edit`)
          }
          collapsible={collapsible}
          pageIndex={pageIndex}
          setPagination={setPagination}
          pageCount={data?.result?.last_page}
        />{" "}
      </ErrorBoundary>
    </div>
  );
}

export default CommunityRequestDraftsTable;
