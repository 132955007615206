import { useQuery } from "@tanstack/react-query";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { formatDateForAPI } from "../utils";

const SEARCH_API_URL = `https://api.sam.gov/prod/opportunities/v2/search?api_key=${process.env.REACT_APP_SAMGOV_API_KEY}`;

export const useFetchOpportunities = (
  searchParams,
  pageSize,
  pageIndex = 0,
  enabled
) => {
  const axiosPrivate = useAxiosPrivate();
  const currentDate = new Date();
  const monthsAgo = new Date();
  monthsAgo.setMonth(currentDate.getMonth() - 11);

  return useQuery({
    queryKey: ["opportunities", pageIndex, searchParams],
    queryFn: () =>
      axiosPrivate
        .get(SEARCH_API_URL, {
          params: {
            postedFrom: formatDateForAPI(monthsAgo),
            postedTo: formatDateForAPI(currentDate),
            ptype: "k",
            limit: pageSize,
            offset: pageIndex * pageSize,
            ...searchParams,
          },
        })
        .then((res) => res.data),
    enabled: enabled,
  });
};

export const useFetchOpportunity = (noticeId) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["opportunity", noticeId],
    queryFn: () =>
      axiosPrivate
        .get(SEARCH_API_URL, {
          params: {
            noticeid: noticeId,
          },
        })
        .then((res) => res.data),
    select: (data) => data.opportunitiesData[0],
  });
};

export const useFetchOpportunityDescription = (url) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["opportunityDescription", url],
    queryFn: () =>
      axiosPrivate
        .get(url, {
          params: {
            api_key: process.env.REACT_APP_SAMGOV_API_KEY,
          },
        })
        .then((res) => res.data),
    enabled: !!url,
    select: (data) => data.description,
  });
};
