import "./Bids.scss";

import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import OrgHeader from "../components/navigation/OrgHeader";
import DataGrid from "../components/DataGrid";

function Bids() {
  return (
    <div className="bids">
      <OrgHeader />
      <h3>Opportunities</h3>
      <Tabs defaultActiveKey="none">
        <Tab eventKey="home" title="Community RFP's" disabled></Tab>
        <Tab eventKey="profile" title="Private Request" disabled></Tab>
        <Tab eventKey="contact" title="Job Board" disabled></Tab>
        <Tab eventKey="contact" title="Government Listings" disabled></Tab>
        <Tab eventKey="contact" title="Government Grants" disabled></Tab>
      </Tabs>
      <DataGrid comingSoon />
    </div>
  );
}

export default Bids;
