import React from "react";
import { Alert } from "react-bootstrap";
import Button from "./Button";

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <Alert variant="danger">
      <p>Something went wrong:</p>
      <p>{error.message}</p>
      <Button variant="danger" onClick={resetErrorBoundary}>
        Try Again
      </Button>
    </Alert>
  );
}

export default ErrorFallback;
