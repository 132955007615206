import React from "react";
import { Spinner } from "react-bootstrap";
import Btn from "react-bootstrap/Button";

function Button({ children, loading, disabled, ...props }) {
  return (
    <Btn {...props} disabled={disabled || loading}>
      {loading && <Spinner as="span" animation="border" size="sm" />} {children}
    </Btn>
  );
}

export default Button;
